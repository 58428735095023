import React, { useState, useRef } from "react";
import { MdOutlineClose } from "react-icons/md";
import { TbMessage } from "react-icons/tb";
import api from "../utils/api";

const FloatingChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const modalRef = useRef(null);

  const handleButtonClick = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("/api/v1/notifications/contact", {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
      });
    } catch (err) {
      console.log(err);
    }
    console.log("Form submitted with data:", formData);
    setIsChatOpen(false);
  };

  return (
    <div className="relative text-black">
      <button
        className="fixed bottom-6 right-4 bg-blue-500 text-white rounded-full p-4 shadow-lg hover:bg-blue-600 text-xl"
        onClick={handleButtonClick}
      >
        {isChatOpen ? <MdOutlineClose /> : <TbMessage />}
      </button>

      {isChatOpen && (
        <div
          ref={modalRef}
          className="fixed bottom-16 right-4 bg-white w-80 rounded-lg shadow-lg border border-gray-200 flex flex-col"
        >
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold">Talk to an expert</h2>
            <button
              className="text-gray-500 hover:text-gray-700 text-xl"
              onClick={handleButtonClick}
            >
              <MdOutlineClose />
            </button>
          </div>
          <div className="flex-1 p-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  type="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                  required
                />
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingChatButton;
