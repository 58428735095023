import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaBars, FaTimes } from "react-icons/fa";
import { HiUpload, HiOutlineCreditCard, HiBell } from "react-icons/hi";
import { IoCallOutline } from "react-icons/io5";
// import ThemeToggle from "../utils/ThemeToggle";
import { LOGOUT } from "../actions/types";
import { Button } from "flowbite-react";

const Header = ({
  toggleSidebar,
  isSidebarVisible,
  showWithDrawModal,
  showDepositModal,
  handleOnClickTalkToAdvisor,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bellHovered, setBellHovered] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector((s) => s.auth.user.username);
  const notifications = useSelector((s) => s.auth.notifications);
  const role = useSelector((s) => s.auth.user.role);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const onLogout = () => {
    dispatch({ type: LOGOUT });
  };

  const handleNotificationClick = () => {
    if (role) navigate("/admin/notifications");
    else navigate("/notifications");
  };

  return (
    <header className="flex items-center justify-between p-4 bg-gray-50 z-40 dark:bg-gray-800 text-gray-800 dark:text-white border-b border-gray-200 dark:border-gray-700 w-full">
      <div className="flex items-center space-x-2">
        <button
          className="lg:hidden text-2xl text-text-dark"
          onClick={toggleSidebar}
        >
          {isSidebarVisible ? <FaTimes /> : <FaBars />}
        </button>
        <Link to="/account">
          <img src="/logo.png" alt="Logo" className="h-12" />
        </Link>
      </div>
      <div className="flex items-center space-x-4">
        {role === 0 ? (
          <Button
            color="blue"
            className="mr-2 hidden md:block"
            onClick={showWithDrawModal}
          >
            <div className="flex items-center">
              <HiUpload className="inline-block" />
              &nbsp;Withdraw
            </div>
          </Button>
        ) : null}
        {role === 0 ? (
          <Button
            color="blue"
            className="hidden md:block"
            style={{ lineHeight: "1.5" }}
            onClick={showDepositModal}
          >
            <div className="flex items-center">
              <HiOutlineCreditCard className="inline-block" />
              &nbsp;Deposit
            </div>
          </Button>
        ) : null}
        {role === 0 ? (
          <Button
            color="blue"
            className="hidden md:block"
            style={{ lineHeight: "1.5" }}
            onClick={handleOnClickTalkToAdvisor}
          >
            <div className="flex items-center">
              <IoCallOutline className="inline-block" />
              &nbsp;Talk to your advisor
            </div>
          </Button>
        ) : null}
        <div
          className="relative"
          onMouseEnter={() => setBellHovered(true)}
          onMouseLeave={() => setBellHovered(false)}
        >
          <button
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 relative text-text-dark"
            onClick={handleNotificationClick}
          >
            <HiBell className="h-6 w-6" />
            {notifications > 0 && (
              <span className="absolute top-0 right-0 inline-block w-4 h-4 text-xs text-white bg-red-600 rounded-full flex items-center justify-center">
                {notifications}
              </span>
            )}
          </button>
          {bellHovered && notifications === 0 && (
            <div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded shadow-lg">
              <div className="p-4 text-center text-gray-500 dark:text-gray-400">
                No notifications
              </div>
            </div>
          )}
        </div>
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span>{userName}</span>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded shadow-lg z-50">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onLogout();
                }}
                className="block w-full text-left px-4 py-2 text-gray-800 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
