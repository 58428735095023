import { Button, Card, Table, Badge, Checkbox } from "flowbite-react";
import api from "../../utils/api";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiPencil, HiTrash } from "react-icons/hi";
import ConfirmModal from "../../components/ConfirmModal";
import ChangeAdminModal from "../../components/ChangeAdminModal";

const UserManagement = () => {
  const [tableData, setTableData] = useState([]);
  const userId = useSelector((s) => s.auth.user.id);
  const role = useSelector((s) => s.auth.user.role);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDel, setSeletedDel] = useState(0);

  const [showChangeAdminModal, setShowChangeAdminModal] = useState(false);

  // const [currentPage, setCurrentPage] = useState(1);

  // const onPageChange = (page) => setCurrentPage(page);

  const handleCheckboxChange = (id) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(id)) {
        // If ID is already in the array, remove it
        return prevSelectedUsers.filter((selectedUser) => selectedUser !== id);
      } else {
        // Otherwise, add the ID to the array
        return [...prevSelectedUsers, id];
      }
    });
  };

  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const cancelChangeAdminModal = () => {
    setShowChangeAdminModal(false);
  };

  const confirmDelete = async () => {
    try {
      const response = (await api.delete(`/api/v1/users/user/${selectedDel}`))
        .data;
      setTableData(response);
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const submitChangeAdminModal = async (employeeId) => {
    try {
      const response = (
        await api.patch(`/api/v1/users/assign`, {
          admin: employeeId,
          users: selectedUsers,
        })
      ).data;
      setTableData(response);
      setShowChangeAdminModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response = [];
        if (role === 1) {
          response = (await api.get("/api/v1/users/users")).data;
        } else if (role === 2) {
          response = (await api.get(`/api/v1/users/employee/${userId}`)).data
            .users;
        }

        setTableData(response);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, [userId, role]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          User Management
        </h1>
        {role === 1 ? (
          <div className="flex gap-2">
            <Link to="/admin/user-management/users/new">
              <Button color="blue">Add User</Button>
            </Link>
            <Button
              color="blue"
              onClick={() => setShowChangeAdminModal(true)}
              disabled={!selectedUsers.length}
            >
              Change admin
            </Button>
            {/* <Button color="blue">Add Employee</Button> */}
          </div>
        ) : null}
      </div>
      {/* <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800"> */}
      <Card className="overflow-x-auto">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold dark:text-white">All Users</h3>
        </div>
        <Table>
          <Table.Head>
            {role === 1 ? (
              <Table.HeadCell></Table.HeadCell>
            ) : (
              <Table.HeadCell>No</Table.HeadCell>
            )}

            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            {role === 1 ? <Table.HeadCell>Admin</Table.HeadCell> : null}
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {tableData.map((row, index) => (
              <Table.Row
                key={index}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                {role === 1 ? (
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <Checkbox
                      name="checked"
                      value={selectedUsers.includes(row.id)}
                      onClick={() => handleCheckboxChange(row.id)}
                    />
                  </Table.Cell>
                ) : (
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {index + 1}
                  </Table.Cell>
                )}

                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.username}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.email}
                </Table.Cell>
                {role === 1 ? (
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {row.adminId ? (
                      <Badge className="inline-block">{row.adminName}</Badge>
                    ) : (
                      <Badge color="failure" className="inline-block">
                        No assigned
                      </Badge>
                    )}
                  </Table.Cell>
                ) : null}
                {/* <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  <StatusComponent status={row.isVerified} />
                </Table.Cell> */}
                <Table.Cell className="flex space-x-3">
                  <Link
                    to={
                      row.role === 0
                        ? `/admin/user-management/users/${row.id}`
                        : `/admin/user-management/employees/${row.id}`
                    }
                  >
                    <HiPencil className="h-5 w-5 text-blue-500 cursor-pointer" />
                  </Link>
                  {role === 1 ? (
                    <HiTrash
                      className="h-5 w-5 text-red-500 cursor-pointer"
                      onClick={() => {
                        setSeletedDel(row.id);
                        setShowDeleteModal(true);
                      }}
                    />
                  ) : null}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {/* <div className="flex overflow-x-auto sm:justify-end">
          <Pagination
            currentPage={currentPage}
            totalPages={100}
            onPageChange={onPageChange}
          />
        </div> */}
      </Card>
      <ConfirmModal
        showDeleteModal={showDeleteModal}
        cancelDeleteModal={cancelDeleteModal}
        confirmDelete={confirmDelete}
      />
      {role === 1 ? (
        <ChangeAdminModal
          showModal={showChangeAdminModal}
          cancelModal={cancelChangeAdminModal}
          submit={submitChangeAdminModal}
        />
      ) : null}
    </div>
  );
};

export default UserManagement;
