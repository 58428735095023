import { Button, Card, Table } from "flowbite-react";
import api from "../../utils/api";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiPencil, HiTrash } from "react-icons/hi";
import ConfirmModal from "../../components/ConfirmModal";

const EmployeeManagement = () => {
  const [tableData, setTableData] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDel, setSeletedDel] = useState(0);

  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    try {
      const response = (
        await api.delete(`/api/v1/users/employee/${selectedDel}`)
      ).data;
      setTableData(response);
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/api/v1/users/employees");
        setTableData(response.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          User Management
        </h1>
        <div className="flex gap-2">
          <Link to="/admin/user-management/employees/new">
            <Button color="blue">Add Employee</Button>
          </Link>
        </div>
      </div>
      {/* <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800"> */}
      <Card className="overflow-x-auto">
        <h3 className="text-xl font-semibold dark:text-white">All Employees</h3>
        <Table>
          <Table.Head>
            <Table.HeadCell>No</Table.HeadCell>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            {/* <Table.HeadCell>Role</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell> */}
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {tableData.map((row, index) => (
              <Table.Row
                key={index}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {index + 1}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.username}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.email}
                </Table.Cell>
                {/* <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.role === 0 ? "User" : "Employee"}
                </Table.Cell>
                <Table.Cell>
                  {<StatusComponent status={row.isVerified} />}
                </Table.Cell> */}
                <Table.Cell className="flex space-x-3">
                  <Link
                    to={
                      row.role === 0
                        ? `/admin/user-management/users/${row.id}`
                        : `/admin/user-management/employees/${row.id}`
                    }
                  >
                    <HiPencil className="h-5 w-5 text-blue-500 cursor-pointer" />
                  </Link>
                  <HiTrash
                    className="h-5 w-5 text-red-500 cursor-pointer"
                    onClick={() => {
                      setSeletedDel(row.id);
                      setShowDeleteModal(true);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
      <ConfirmModal
        showDeleteModal={showDeleteModal}
        cancelDeleteModal={cancelDeleteModal}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default EmployeeManagement;
