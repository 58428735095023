import {
  Button,
  TextInput,
  Label,
  Card,
  Textarea,
  Select,
} from "flowbite-react";
import api from "../../utils/api";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
// import Select from "react-tailwindcss-select";
// import "react-tailwindcss-select/dist/index.css";

const AdminContractDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    offerId: -1,
    offerTitle: "",
    userId: -1,
    userName: "",
    adminName: "",
    status: 0,
    comment: "",
  });

  const [userList, setUserList] = useState([]);
  const [offerList, setOfferList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/contracts/id/${id}`);
        const data = response.data;
        setFormData({ ...data });
      } catch (err) {
        console.log(err);
      }
    }

    if (id !== undefined) fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let response;
        response = await api.get("/api/v1/users/users");
        setUserList(response.data);
        response = await api.get("/api/v1/offers/all");

        setOfferList(response.data);
      } catch (err) {
        console.log(err);
      }
    }

    if (id === undefined) fetchData();
  }, [id]);

  const handleStatusChange = (e) => {
    setFormData({ ...formData, status: e.target.value });
  };

  const handleCommentChange = (e) => {
    setFormData({ ...formData, comment: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.offerId === -1 || formData.userId === -1) {
        return alert("Select valid");
      } else if (id === undefined) {
        await api.post(`/api/v1/contracts/new`, { ...formData });
        navigate(-1);
      } else {
        await api.patch(`/api/v1/contracts/comment/${id}`, { ...formData });
        navigate("/admin/contracts/all");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "userId") {
      const user = userList.find((item) => item.id === Number(value));
      setFormData({ ...formData, [name]: value, adminName: user?.adminName });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          {id === undefined ? "New Contract" : "Contact"}
        </h1>
      </div>
      <Card>
        <h3 className="text-xl font-semibold dark:text-white">
          Contract Information
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="offer" value="Offer" />
              </div>
              <Select
                id="offer"
                name="offerId"
                value={formData.offerId}
                className="w-full"
                onChange={id === undefined ? handleChange : null}
                required={id === undefined}
                readOnly={id !== undefined}
              >
                {id === undefined ? (
                  <option value="">Select an offer</option>
                ) : null}
                {id === undefined ? (
                  offerList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  ))
                ) : (
                  <option value={formData.offerId}>
                    {formData.offerTitle}
                  </option>
                )}
              </Select>
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="status" value="Status" />
              </div>
              <Select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleStatusChange}
                className="w-full flex items-center"
                required={id === undefined}
              >
                <option value={0} key={0}>
                  Pending
                </option>
                <option value={1} key={1}>
                  Approved
                </option>
                <option value={-1} key={-1}>
                  Rejected
                </option>
              </Select>
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="users" value="User" />
              </div>
              <div className="bg-multiselect dark:bg-multiselect">
                <Select
                  id="users"
                  name="userId"
                  value={formData.userId}
                  className="w-full"
                  onChange={id === undefined ? handleChange : null}
                  required={id === undefined}
                  readOnly={id !== undefined}
                >
                  {id === undefined ? (
                    <option value="">Select a user</option>
                  ) : null}
                  {id === undefined ? (
                    userList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.username}
                      </option>
                    ))
                  ) : (
                    <option value={formData.userId}>{formData.userName}</option>
                  )}
                </Select>
              </div>
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="admin" value="Admin" />
              </div>
              <TextInput
                id="admin"
                defaultValue={formData.adminName}
                readOnly
                disabled
              />
            </div>

            <div className="col-span-8">
              <div className="mb-2 block">
                <Label htmlFor="comment" value="Comment" />
              </div>
              <Textarea
                id="comment"
                name="comment"
                value={formData.comment}
                onChange={handleCommentChange}
                className="p-2.5"
                rows={4}
              />
            </div>
            <div className="col-span-8 sm:col-ful2">
              <Button color="blue" type="submit">
                Save all
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AdminContractDetailPage;
