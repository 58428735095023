import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import store from "../store";
import { fetchNotifications } from "../actions/notificationActions";
import PrivateHeader from "./PrivateHeader";
import PrivateSidebar from "./PrivateSidebar";
import WithdrawModal from "./WithdrawModal";
import DepositModal from "./DepositModal";
import api from "../utils/api";
import { toast } from "react-toastify";

const Layout = ({ children }) => {
  const location = useLocation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isShowWithDraw, setIsShowWithDraw] = useState(false);
  const [isShowDeposit, setIsShowDeposit] = useState(false);
  const [withDrawData, setWithDrawData] = useState({
    amount: 0,
    purpose: "",
    walletAddress: "",
    network: "ETH",
    account: "",
    balance: 0,
  });
  const [depositData, setDepositData] = useState({
    amount: 0,
    purpose: "",
    method: 0,
    account: "",
    balance: 0,
  });

  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    const fetchAccountList = async () => {
      try {
        const response = await api.get("/api/v1/account/user");
        setAccountList(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    const fetchData = async () => {
      try {
        await store.dispatch(fetchNotifications());
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.warn("Server error", { autoClose: 3000 });
      }
    };

    fetchData();
    fetchAccountList();
  }, [location]);

  const handleChangeWithDraw = (e) => {
    const { name, value } = e.target;
    if (name === "account") {
      const account = accountList.find(
        (item) => item.id === Number(e.target.value)
      );
      setWithDrawData({
        ...withDrawData,
        [name]: value,
        balance: account?.balance || 0,
      });
    } else {
      setWithDrawData({ ...withDrawData, [name]: value });
    }
  };

  const handleChangeDeposit = (e) => {
    const { name, value } = e.target;
    if (name === "account") {
      const account = accountList.find(
        (item) => item.id === Number(e.target.value)
      );
      setDepositData({
        ...depositData,
        [name]: value,
        balance: account?.balance || 0,
      });
    } else {
      setDepositData({ ...depositData, [name]: value });
    }
  };

  const onSubmitWithdraw = async (e) => {
    try {
      await api.post("/api/v1/transaction/withdraw", { ...withDrawData });
      toast.success("Your request has been submitted", { autoClose: 3000 });
      setWithDrawData({
        amount: 0,
        purpose: "",
        walletAddress: "",
        network: "ETH",
        account: "",
        balance: 0,
      });
      closeWithDrawModal();
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const onSubmitDeposit = async (e) => {
    try {
      await api.post("/api/v1/transaction/deposit", { ...depositData });
      toast.success("Your request has been submitted", { autoClose: 3000 });

      setDepositData({
        amount: 0,
        purpose: "",
        method: 0,
        account: "",
        balance: 0,
      });
      closeDepositModal();
      window.open("https://nowpayments.io/pos-terminal/wwmvip/", "_blank");
    } catch (err) {
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const handleOnClickTalkToAdvisor = async (e) => {
    try {
      await api.post("/api/v1/notifications/talk-to-advisor", {
        ...depositData,
      });
      toast.success("Your request has been submitted", { autoClose: 3000 });
    } catch (err) {
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const closeWithDrawModal = () => {
    setIsShowWithDraw(false);
  };

  const closeDepositModal = () => {
    setIsShowDeposit(false);
  };

  const showDepositModal = () => {
    setIsShowDeposit(true);
  };

  const showWithDrawModal = () => {
    setIsShowWithDraw(true);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="flex flex-col h-screen">
      <PrivateHeader
        toggleSidebar={toggleSidebar}
        isSidebarVisible={isSidebarVisible}
        showDepositModal={showDepositModal}
        showWithDrawModal={showWithDrawModal}
        handleOnClickTalkToAdvisor={handleOnClickTalkToAdvisor}
      />
      <div className="md:flex md:flex-1 md:relative">
        <div
          className={`fixed inset-0 z-20 bg-black bg-opacity-50 md:bg-opacity-0 md:hidden ${
            isSidebarVisible ? "block" : "hidden"
          }`}
          onClick={toggleSidebar}
        ></div>
        <div
          className={`mt-20 md:mt-0 fixed z-30 inset-y-0 left-0 transform ${
            isSidebarVisible ? "translate-x-0" : "-translate-x-full"
          } md:relative md:translate-x-0 w-64 border-r border-gray-200 dark:border-gray-700 transition-transform duration-300 ease-in-out`}
        >
          <PrivateSidebar
            toggleSidebar={toggleSidebar}
            isSidebarVisible={isSidebarVisible}
            showWithDrawModal={showWithDrawModal}
            showDepositModal={showDepositModal}
            handleOnClickTalkToAdvisor={handleOnClickTalkToAdvisor}
          />
        </div>
        <main className="flex-1 p-4 bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
          {children}
        </main>
      </div>
      <DepositModal
        accountList={accountList}
        isShowDeposit={isShowDeposit}
        closeDepositModal={closeDepositModal}
        depositData={depositData}
        handleChangeDeposit={handleChangeDeposit}
        onSubmitDeposit={onSubmitDeposit}
      />
      <WithdrawModal
        accountList={accountList}
        isShowWithDraw={isShowWithDraw}
        closeWithDrawModal={closeWithDrawModal}
        withDrawData={withDrawData}
        handleChangeWithDraw={handleChangeWithDraw}
        onSubmitWithdraw={onSubmitWithdraw}
      />
    </div>
  );
};

export default Layout;
