import {
  Modal,
  TextInput,
  Select,
  Textarea,
  Label,
  Button,
} from "flowbite-react";
import { convertType } from "../utils/config";

const WithdrawModal = ({
  isShowWithDraw,
  closeWithDrawModal,
  withDrawData,
  handleChangeWithDraw,
  onSubmitWithdraw,
  accountList,
}) => {
  return (
    <Modal
      id="withdraw-modal"
      show={isShowWithDraw}
      size="md"
      onClose={closeWithDrawModal}
    >
      <Modal.Header className="p-4">Withdraw</Modal.Header>
      <Modal.Body className="px-6 pt-2">
        <div className="grid grid-flow-row gap-4">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="account" value="Account" />
            </div>
            <Select
              id="account"
              name="account"
              value={withDrawData.account}
              onChange={handleChangeWithDraw}
              required
            >
              <option value="">Select an account</option>
              {accountList
                .filter((item) => item.status === 1)
                .map((item) => (
                  <option key={item.id} value={item.id}>
                    {convertType(item.type)}
                  </option>
                ))}
            </Select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="balance" value="Balance" />
            </div>
            <TextInput
              id="balance"
              name="balance"
              value={withDrawData.balance.toFixed(2)}
              readOnly
              disabled
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="withdraw-amount" value="Amount" />
            </div>
            <TextInput
              id="withdraw-amount"
              name="amount"
              type="number"
              value={withDrawData.amount}
              onChange={handleChangeWithDraw}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="network" value="Network" />
            </div>
            <Select
              id="network"
              name="network"
              value={withDrawData.network}
              onChange={handleChangeWithDraw}
            >
              <option value={"ETH"}>ETH</option>
              <option value={"BNB"}>BNB</option>
              <option value={"TRX"}>TRX</option>
              <option value={"Polygon"}>Polygon</option>
            </Select>
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="wallet-address" value="Wallet Address" />
            </div>
            <TextInput
              id="wallet-address"
              name="walletAddress"
              value={withDrawData.walletAddress}
              onChange={handleChangeWithDraw}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="purpose" value="Purpose" />
            </div>
            <Textarea
              id="purpose"
              name="purpose"
              value={withDrawData.purpose}
              onChange={handleChangeWithDraw}
              className="p-2.5"
              rows={4}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="blue" onClick={onSubmitWithdraw}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawModal;
