import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "flowbite-react";
import { FaAngleLeft } from "react-icons/fa";
// import Select from "react-tailwindcss-select";
// import "react-tailwindcss-select/dist/index.css";
import api from "../../utils/api";

const NewEmployee = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await api.post("/api/v1/users/employee/register", { ...formData });
      navigate("/admin/user-management/employees");
    } catch (error) {
      console.log(error);
      if (Object.keys(error.response.data.error).length > 0) {
        setErrors({ ...error.response.data.error });
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate("/admin/user-management/employees")}
          />
          New Employee
        </h1>
      </div>
      {/* <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800"> */}
      <Card>
        {/* <div className=""> */}
        <form className="grid grid-cols-8 gap-6" onSubmit={handleSubmit}>
          <div className="col-span-8 md:col-span-2">
            <label
              htmlFor="username"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Name
            </label>
            <input
              type="text"
              name="username"
              id="username"
              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.username ? "dark:border-red-500" : ""
              }`}
              placeholder="John Doe"
              required
              value={formData.username}
              onChange={handleChange}
            />
            {errors.username && (
              <p
                id="outlined_error_help"
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {errors.username}
              </p>
            )}
          </div>
          <div className="col-span-8 md:col-span-2">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.email ? "dark:border-red-500" : ""
              }`}
              placeholder="name@company.com"
              required
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <p
                id="outlined_error_help"
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {errors.email}
              </p>
            )}
          </div>
          <div className="col-span-8 md:col-span-2">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.password ? "dark:border-red-500" : ""
              }`}
              required
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <p
                id="outlined_error_help"
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {errors.password}
              </p>
            )}
          </div>
          <div className="col-span-8 md:col-span-2">
            <label
              htmlFor="password2"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Confirm password
            </label>
            <input
              type="password"
              name="password2"
              id="password2"
              placeholder="••••••••"
              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.password2 ? "dark:border-red-500" : ""
              }`}
              required
              value={formData.password2}
              onChange={handleChange}
            />
            {errors.password2 && (
              <p
                id="outlined_error_help"
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {errors.password2}
              </p>
            )}
          </div>

          <div className="col-span-8 sm:col-ful2">
            <Button color="blue" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default NewEmployee;
