import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Label, Select, TextInput } from "flowbite-react";
import { FaAngleLeft } from "react-icons/fa";
import api from "../../utils/api";
import { toast } from "react-toastify";

const NewAccountClientPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    initialAmount: 0,
    type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post("/api/v1/account/user", {
        type: formData.type,
        amount: formData.initialAmount,
      });
      toast.success("Your request has been submitted", { autoClose: 3000 });
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate("/admin/user-management/users")}
          />
          New Account
        </h1>
      </div>
      <Card>
        <form className="grid grid-cols-8 gap-6" onSubmit={handleSubmit}>
          <div className="col-span-8 md:col-span-2">
            <div className="mb-2 block">
              <Label htmlFor="account-type" value="Type" />
            </div>
            <Select
              id="account-type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Select a type</option>
              <option value="saving">Saving Account</option>
              <option value="credit">Credit Account</option>
              <option value="current">Current Account</option>
              <option value="contract">Contract Account</option>
            </Select>
          </div>
          <div className="col-span-8 md:col-span-2">
            <div className="mb-2 block">
              <Label htmlFor="initial-amount" value="Initial Amount" />
            </div>
            <TextInput
              placeholder="0"
              id="initial-amount"
              name="initialAmount"
              type="number"
              value={formData.initialAmount}
              onChange={handleChange}
            />
          </div>
          <div className="col-span-8">
            <Button color="blue" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default NewAccountClientPage;
