
const Consultants = () => {
    return (
        <div className="section consultants-section text-text-default dark:text-text-dark text-lg">
          <div className="container mx-auto px-8">
            <div className="consultants-block flex flex-col lg:flex-row lg:items-center lg:justify-between">
              <div className="consultants__image mb-8 lg:mb-0">
                <img
                  src="https://leonllp.org/wp-content/themes/investpole/img/wallet.png"
                  alt="Consultant Icon"
                  className="w-full h-auto"
                />
              </div>
              <div className="consultants-info wow" style={{ animationName: 'slideInRight' }}>
                <h2 className="section-title text-3xl font-bold mb-4">
                  Your consultant for a reason
                </h2>
                <div className="consultants__text format-text-p mb-8">
                  We are professionals in our field.<br />
                  You can make sure of this by starting to work with us
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div className="consultant space-x-4">
                    <div className="consultant__icon">
                      <img src="https://leonllp.org/wp-content/themes/investpole/img/consultant-icons/1.svg" alt="Alt" />
                    </div>
                    <div className="consultant__info">
                      <div className="text-xl font-semibold">
                      Expertise
                      </div>
                      <div className="consultant__text ">
                      We have the best experts
                      </div>
                    </div>
                  </div>
                  <div className="consultant space-x-4">
                    <div className="consultant__icon">
                      <img src="https://leonllp.org/wp-content/themes/investpole/img/consultant-icons/2.svg" alt="Alt" />
                    </div>
                    <div className="consultant__info">
                      <div className="text-xl font-semibold">
                      Growth
                      </div>
                      <div className="consultant__text ">
                      Confidently move forward with us
                      </div>
                    </div>
                  </div>
                  <div className="consultant space-x-4">
                    <div className="consultant__icon">
                      <img src="https://leonllp.org/wp-content/themes/investpole/img/consultant-icons/3.svg" alt="Alt" />
                    </div>
                    <div className="consultant__info">
                      <div className="text-xl font-semibold">
                      Dedicated Support
                      </div>
                      <div className="consultant__text">
                        We work without days off and breaks
                      </div>
                    </div>
                  </div>
                  <div className="consultant space-x-4">
                    <div className="consultant__icon">
                      <img src="https://leonllp.org/wp-content/themes/investpole/img/consultant-icons/4.svg" alt="Alt" />
                    </div>
                    <div className="consultant__info">
                      <div className="text-xl font-semibold">
                      Trust
                      </div>
                      <div className="consultant__text ">
                      Answers for all of your questions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Consultants;