import React from "react";
import Header from "../components/Header";
import PaymentBlock from "../components/PaymentBlock";
import Footer from "../components/Footer";
import PriceList from "../components/Home/PriceList";
import CounterUp from "../components/Home/CounterUp";
import Hero from "../components/Home/Hero";
import AdvantageBlock from "../components/Home/AdvantageBlock";
import PlatformBlock from "../components/Home/PlatformBlock";
import Consultants from "../components/Home/Consultants";
import MarketDataWidget from "../components/Home/MarketDataWidget";
import FloatingButton from "../components/FloatingButton";

const Home = () => {
  return (
    <div>
      <Header />
      <div
        className="flex flex-col gap-4 md:gap-16"
        style={{
          backgroundImage:
            'url("https://leonllp.org/wp-content/themes/investpole/img/bg.jpg")',
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="max-w-1320 mx-auto">
          <Hero />
          <PriceList />
        </div>
        <CounterUp />
        <div className="max-w-1320 mx-auto flex flex-col gap-16">
          <MarketDataWidget />
          <AdvantageBlock />
          <PlatformBlock />
          <Consultants />
        </div>
      </div>
      <PaymentBlock />
      <Footer />
      <FloatingButton />
    </div>
  );
};

export default Home;
