import React, { useEffect, useRef } from "react";
import NewsComponent from "../../components/NewsComponent";

const News = () => {
  const containerRef = useRef(null);
  const scriptAppendedRef = useRef(false); // Track whether the first script has been appended
  // const timelineContainerRef = useRef(null);
  // const timelineScriptAppendedRef = useRef(false); // Track whether the second script has been appended

  useEffect(() => {
    const appendEventsScript = () => {
      if (!containerRef.current || scriptAppendedRef.current) return;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
      script.innerHTML = JSON.stringify({
        colorTheme: "dark",
        isTransparent: false,
        height: "550",
        locale: "en",
        importanceFilter: "-1,0,1",
        countryFilter:
          "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu",
      });

      containerRef.current.appendChild(script);
      scriptAppendedRef.current = true;
    };

    // const appendTimelineScript = () => {
    //     if (!timelineContainerRef.current || timelineScriptAppendedRef.current) return;

    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.async = true;
    //     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
    //     script.innerHTML = JSON.stringify({
    //         feedMode: 'all_symbols',
    //         isTransparent: false,
    //         displayMode: 'regular',
    //         height: 550,
    //         colorTheme: 'dark',
    //         locale: 'en'
    //     });

    //     timelineContainerRef.current.appendChild(script);
    //     timelineScriptAppendedRef.current = true;
    // };

    // Ensure both scripts are appended after the component is mounted
    setTimeout(() => {
      appendEventsScript();
      // appendTimelineScript();
    }, 0);

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ""; // Clean up the container on unmount
        scriptAppendedRef.current = false;
      }
      // if (timelineContainerRef.current) {
      //     timelineContainerRef.current.innerHTML = ''; // Clean up the container on unmount
      //     timelineScriptAppendedRef.current = false;
      // }
    };
  }, []);

  return (
    <div className="grid grid-flow-rows gap-4 mb-4">
      <div className="p-4">
        <div className="text-3xl font-bold mt-2">News</div>
        <div className="mt-8">
          <NewsComponent />
          {/* <div className="tradingview-widget-container" ref={timelineContainerRef}>
                        <div className="tradingview-widget-container__widget"></div>
                        <div className="tradingview-widget-copyright">
                            <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
                                <span className="blue-text">Track all markets on TradingView</span>
                            </a>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="p-4">
        <div className="text-3xl font-bold mt-2">Economic Calendar</div>
        <div className="mt-8">
          <div className="tradingview-widget-container" ref={containerRef}>
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright">
              <a
                href="https://www.tradingview.com/"
                rel="noopener nofollow"
                target="_blank"
              >
                <span className="blue-text">
                  Track all markets on TradingView
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
