import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "flowbite-react";
import api from "../../utils/api";
import AccountCard from "../../components/AccountCard";
import TransferModal from "../../components/TransferModal";
import { toast } from "react-toastify";

const ClientAccountPage = () => {
  const [accountTableData, setAccountTableData] = useState([]);
  const [isShowTransfer, setIsShowTransfer] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/api/v1/account/user");
        setAccountTableData(response.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  const closeTransferModal = () => {
    setIsShowTransfer(false);
  };

  const onSubmitTransfer = async (transferData) => {
    const response = await api.patch("/api/v1/account/transfer-by-user", {
      from: transferData.from,
      to: transferData.to,
      amount: transferData.amount,
    });
    setAccountTableData(response.data);
    toast.success("Succeeded", { autoClose: 3000 });

    closeTransferModal();
    return response;
  };

  const deleteAccount = async (id) => {
    try {
      await api.patch(`/api/v1/account/pending/close/${id}`);
      const filteredData = accountTableData.map((item) =>
        Number(item.id) === Number(id)
          ? { ...item, status: 0, detail: "Close" }
          : item
      );
      setAccountTableData(filteredData);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const cancelPendingOpenAccount = async (id) => {
    try {
      await api.delete(`/api/v1/account/${id}`);
      const filteredData = accountTableData.filter(
        (item) => Number(item.id) !== Number(id)
      );
      setAccountTableData(filteredData);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const cancelPendingCloseAccount = async (id) => {
    try {
      await api.patch(`/api/v1/account/active/${id}`);
      const filteredData = accountTableData.map((item) =>
        Number(item.id) === Number(id)
          ? { ...item, status: 1, detail: "" }
          : item
      );
      setAccountTableData(filteredData);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  return (
    <div className="p-0">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Account
        </h1>
        <div className="flex gap-2">
          <Link to="/account/new">
            <Button color="blue">Add Account</Button>
          </Link>
          <Button color="blue" onClick={() => setIsShowTransfer(true)}>
            Transfer
          </Button>
        </div>
      </div>
      <AccountCard
        role={0}
        accountTableData={accountTableData}
        deleteAccount={deleteAccount}
        cancelPendingOpenAccount={cancelPendingOpenAccount}
        cancelPendingCloseAccount={cancelPendingCloseAccount}
      />
      <TransferModal
        isShowTransfer={isShowTransfer}
        closeTransferModal={closeTransferModal}
        onSubmitTransfer={onSubmitTransfer}
        accountList={accountTableData}
      />
    </div>
  );
};

export default ClientAccountPage;
