import api from "../../utils/api";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TransactionListCard from "../../components/TransactionListCard";

const TransactionListPage = () => {
  const [tableData, setTableData] = useState([]);
  const role = useSelector((s) => s.auth.user.role);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = (await api.get("/api/v1/transaction/all")).data;
        setTableData(response);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  const confirmDelete = async (selectedDel) => {
    try {
      const response = (await api.delete(`/api/v1/transaction/${selectedDel}`))
        .data;
      setTableData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeStatus = async (id, selected) => {
    try {
      const response = await api.patch(`/api/v1/transaction/status/${id}`, {
        status: selected,
        prev: tableData.find((item) => item.id === id)?.status,
        amount: tableData.find((item) => item.id === id)?.amount,
        userId: tableData.find((item) => item.id === id)?.userId,
        accountId: tableData.find((item) => item.id === id)?.accountId,
        type: tableData.find((item) => item.id === id)?.type,
        isUser: false,
      });

      setTableData(response.data);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Transactions
        </h1>
      </div>
      <TransactionListCard
        role={role}
        tableData={tableData}
        onChangeStatus={onChangeStatus}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default TransactionListPage;
