import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, TextInput, Label, Button } from "flowbite-react";
import { FaAngleLeft } from "react-icons/fa";
import Select from "react-tailwindcss-select";
// import "react-tailwindcss-select/dist/index.css";
import api from "../../utils/api";

const EmployeeDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    registrationDate: "",
    lastLoginDate: "",
    userId: "",
    profileId: "",
  });

  const [users, setUsers] = useState([]);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/users/employee/${id}`);
        const data = response.data;
        setFormData({
          username: data.username || "",
          email: data.email || "",
          registrationDate: data.registrationDate || "",
          lastLoginDate: data.lastLoginDate || "",
          userId: data.id || "",
          profileId: data.profileId || "",
        });
        setUsers(
          data.users.map((item) => ({
            value: item.id,
            label: item.username,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/api/v1/users/users");
        const data = response.data;
        setUserList(data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      await api.patch(`/api/v1/users/employee/${formData.userId}`, {
        ...formData,
        users: users.map((item) => item.value),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserOptionChange = (selected) => {
    setUsers(selected);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate("/admin/user-management/employees")}
          />
          Employee Detail
        </h1>
      </div>
      {/* <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800"> */}
      <Card>
        <div className="grid grid-cols-8 gap-6">
          <div className="col-span-8 md:col-span-2">
            <div className="mb-2 block">
              <Label htmlFor="username" value="Name" />
            </div>
            <TextInput
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              readOnly
            />
          </div>
          <div className="col-span-8 md:col-span-2">
            <div className="mb-2 block">
              <Label htmlFor="email" value="Email" />
            </div>
            <TextInput id="email" defaultValue={formData.email} readOnly />
          </div>
          <div className="col-span-8 md:col-span-2">
            <div className="mb-2 block">
              <Label htmlFor="registrationDate" value="Registration Date" />
            </div>
            <TextInput
              id="registrationDate"
              defaultValue={formData.registrationDate}
              readOnly
              disabled
            />
          </div>
          <div className="col-span-8 md:col-span-2">
            <div className="mb-2 block">
              <Label htmlFor="lastLoginDate" value="Last Login" />
            </div>
            <TextInput
              id="lastLoginDate"
              defaultValue={formData.lastLoginDate}
              readOnly
              disabled
            />
          </div>
          <div className="col-span-8">
            <div className="mb-2 block">
              <Label htmlFor="users" value="Users" />
            </div>
            {/* <MultiSelect
              id="users"
              data={userList.map((item) => ({
                value: item.id,
                label: item.username,
              }))}
              selected={users}
            /> */}
            <div className="bg-dark-200 bg-multiselect dark:bg-dark-200 dark:bg-multiselect">
              <Select
                value={users}
                onChange={handleUserOptionChange}
                options={userList.map((item) => ({
                  value: item.id,
                  label: item.username,
                }))}
                isMultiple
                isSearchable
                primaryColor="accent"
              />
            </div>
          </div>
        </div>
        <div className="col-span-8 sm:col-ful2">
          <Button color="blue" onClick={handleSubmit}>
            Save all
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default EmployeeDetail;
