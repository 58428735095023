import { Modal } from "flowbite-react";

const ConfirmModal = ({
  showDeleteModal,
  cancelDeleteModal,
  confirmDelete,
}) => {
  return (
    <Modal show={showDeleteModal} size="md" onClose={cancelDeleteModal}>
      <Modal.Header>Confirm Delete</Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this item?</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={confirmDelete}
          className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
        >
          Yes
        </button>
        <button
          onClick={cancelDeleteModal}
          className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700"
        >
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
