import React, { useEffect, useRef } from "react";

const NewsComponent = () => {
  const timelineContainerRef = useRef(null);
  const timelineScriptAppendedRef = useRef(false); // Track whether the second script has been appended

  useEffect(() => {
    const appendTimelineScript = () => {
      if (!timelineContainerRef.current || timelineScriptAppendedRef.current)
        return;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
      script.innerHTML = JSON.stringify({
        feedMode: "all_symbols",
        isTransparent: false,
        displayMode: "regular",
        height: 550,
        colorTheme: "dark",
        locale: "en",
      });

      timelineContainerRef.current.appendChild(script);
      timelineScriptAppendedRef.current = true;
    };

    setTimeout(() => {
      appendTimelineScript();
    }, 0);

    return () => {
      if (timelineContainerRef.current) {
        timelineContainerRef.current.innerHTML = ""; // Clean up the container on unmount
        timelineScriptAppendedRef.current = false;
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={timelineContainerRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright"></div>
    </div>
  );
};

export default NewsComponent;
