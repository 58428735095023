const PlatformBlock = () => {
    return (
        <div className="section platforms-section text-lg p-6 md:pd-0">
            <div className="container">
                <div className="platforms-block">
                <div className="platforms-info wow" style={{ animationName: 'slideInLeft' }}>
                    <h2 className="section-title">Keep up to date with the market</h2>
                    <div className="platforms__text format-text-p">
                    Weather you are looking for crypto prices, Forex Market, or Stocks you can download TradingView and enjoy a free experience of charting tools.
                    </div>
                    <div className="support-icons platforms__support-icons">
                        <div className='flex gap-2 justify-center md:justify-start'>
                            <a href="https://www.tradingview.com/" target="_blank" rel="noreferrer" className="flex items-center px-4 py-3 bg-secondary-dark dark:bg-bg-secondary text-text-default dark:text-text-dark font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 rounded-full">
                                <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.00133 7.30533L13.8373 5.95067V15.4533H4V7.30667L4.00133 7.30533ZM4.00133 24.6947L13.8373 26.0507V16.664H4L4.00133 24.6947ZM14.9187 26.1947L28.0013 28V16.664H14.9187V26.1947ZM14.9187 5.80533V15.4533H28.0013V4L14.9187 5.80533Z"
                                        fill="#44EC8A" />
                                </svg>
                            </a>
                            <a href="https://apps.apple.com/us/app/tradingview-track-all-markets/id1205990992" rel="noreferrer" className="flex items-center px-4 py-3 bg-secondary-dark dark:bg-bg-secondary text-text-default dark:text-text-dark font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
                                <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M23.3302 27.9569C21.9087 29.3289 20.3567 29.1123 18.8626 28.4623C17.2816 27.798 15.8311 27.7691 14.163 28.4623C12.0743 29.3578 10.9719 29.0978 9.72442 27.9569C2.64594 20.6922 3.6903 9.62916 11.7261 9.22477C13.6843 9.32587 15.0478 10.2935 16.1937 10.3802C17.9053 10.0336 19.5444 9.03702 21.372 9.167C23.5623 9.34031 25.2159 10.2069 26.3038 11.7667C21.7782 14.4674 22.8515 20.4034 27 22.0643C26.1732 24.2307 25.0998 26.3826 23.3157 27.9713L23.3302 27.9569ZM16.0486 9.13811C15.8311 5.91741 18.4565 3.25997 21.4736 3C21.8942 6.7262 18.0794 9.49918 16.0486 9.13811Z"
                                    fill="#44EC8A" />
                                </svg>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.tradingview.tradingviewapp" rel="noreferrer" className="flex items-center px-4 py-3 bg-secondary-dark dark:bg-bg-secondary text-text-default dark:text-text-dark font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
                                <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M26.2276 17.2981L22.2267 19.5161L18.6382 16.0231L22.2553 12.502L26.2276 14.704C26.462 14.8339 26.6571 15.0224 26.7928 15.2503C26.9285 15.4782 27 15.7372 27 16.0011C27 16.2649 26.9285 16.524 26.7928 16.7518C26.6571 16.9797 26.462 17.1682 26.2276 17.2981ZM5.11434 4.92395C5.03858 5.10411 4.99973 5.2971 5 5.49195V26.5092C5 26.7262 5.04594 26.9282 5.12659 27.1092L16.5147 16.0221L5.11434 4.92395ZM17.5765 14.989L20.9026 11.751L7.27151 4.19494C6.97933 4.03047 6.63905 3.9674 6.30573 4.01594L17.5765 14.989ZM17.5765 17.0561L6.34657 27.9892C6.6508 28.0252 6.97136 27.9732 7.27151 27.8062L20.874 20.2661L17.5765 17.0561Z"
                                    fill="#44EC8A" />
                                </svg>
                            </a>
                            </div>
                        </div>
                    </div>
                    <div className="platforms-mockups wow" style={{ animationName: 'slideInRight' }}>
                        <img src="https://leonllp.org/wp-content/themes/investpole/img/mockups.png" alt="Alt" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlatformBlock;