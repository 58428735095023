import {
  Button,
  Card,
  Table,
  Modal,
  Textarea,
  TextInput,
  Label,
  Checkbox,
} from "flowbite-react";
import api from "../utils/api";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { HiEye, HiTrash } from "react-icons/hi";
import { VscSend } from "react-icons/vsc";
import Select from "react-tailwindcss-select";
import ConfirmModal from "../components/ConfirmModal";

const NotificationPage = () => {
  const [tableData, setTableData] = useState([]);
  const [detailModalData, setDetailModalData] = useState({
    title: "",
    senderName: "",
    adminName: "",
    content: "",
  });
  const [newModalData, setNewModalData] = useState({
    title: "",
    receivers: [],
    content: "",
    isToAll: false,
  });
  const [isShow, setIsShow] = useState(false);
  const [isNewShow, setIsNewShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDel, setSeletedDel] = useState(0);
  const role = useSelector((s) => s.auth.user.role);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await api.patch("/api/v1/notifications/status");
        const response = (await api.get("/api/v1/notifications/all")).data;
        setTableData(response);
        if (role !== 0) {
          const userresponse = (await api.get("/api/v1/users/users")).data;
          setUserList(userresponse);
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onCloseModal = () => {
    setIsShow(false);
    setDetailModalData({
      title: "",
      senderName: "",
      adminName: "",
      content: "",
    });
  };

  const onCloseNewModal = () => {
    setIsNewShow(false);
  };

  const confirmDelete = async () => {
    try {
      const response = (
        await api.delete(`/api/v1/notifications/${selectedDel}`)
      ).data;
      setTableData(response);
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelected = (id) => {
    setIsShow(true);
    const data = tableData.find((item) => item.id === Number(id));
    setDetailModalData({ ...data });
  };

  const handleNewSelected = () => {
    setIsNewShow(true);
  };

  const handleChangeNew = (e) => {
    const { name, value } = e.target;
    if (name === "isToAll")
      setNewModalData({ ...newModalData, [name]: e.target.checked });
    else setNewModalData({ ...newModalData, [name]: value });
  };

  const handleReceiverChange = (selected) => {
    setNewModalData({ ...newModalData, receivers: selected });
  };

  const handleSendMessage = async () => {
    try {
      await api.post("/api/v1/notifications/message", {
        ...newModalData,
        receivers: newModalData.receivers.map((item) => item.value),
      });
      setIsNewShow(false);
      setNewModalData({
        title: "",
        receivers: [],
        content: "",
        isToAll: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Notifications
        </h1>
        {role ? (
          <div className="flex gap-2">
            <Button color="blue" onClick={handleNewSelected}>
              <VscSend className="h-5 w-5 cursor-pointer" />
              &nbsp; New Message
            </Button>
          </div>
        ) : null}
      </div>
      <Card className="overflow-x-auto">
        {/* <h3 className="text-xl font-semibold dark:text-white">
          All Notifications
        </h3> */}
        <Table>
          <Table.Head>
            <Table.HeadCell>No</Table.HeadCell>
            <Table.HeadCell>Title</Table.HeadCell>
            {role ? <Table.HeadCell>Sender</Table.HeadCell> : null}
            {role ? <Table.HeadCell>Admin</Table.HeadCell> : null}
            <Table.HeadCell>Created Datetime</Table.HeadCell>
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {tableData.map((row, index) => (
              <Table.Row
                key={row.id + 1}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {index + 1}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.title}
                </Table.Cell>
                {role ? (
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {row.senderName}
                  </Table.Cell>
                ) : null}
                {role ? (
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {row.adminName}
                  </Table.Cell>
                ) : null}
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.createDatetime}
                </Table.Cell>
                <Table.Cell className="flex space-x-3 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  <HiEye
                    className="h-5 w-5 text-blue-500 cursor-pointer"
                    onClick={() => handleSelected(row.id)}
                  />
                  <HiTrash
                    className="h-5 w-5 text-red-500 cursor-pointer"
                    onClick={() => {
                      setSeletedDel(row.id);
                      setShowDeleteModal(true);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {tableData.length === 0 ? (
          <div className="text-lg p-4 text-center">
            There is no notification.
          </div>
        ) : null}
      </Card>
      <Modal show={isShow} onClose={onCloseModal}>
        <Modal.Header className="p-4">Notification</Modal.Header>
        <Modal.Body className="px-6 pt-4">
          <div className="grid grid-cols-8 gap-4">
            <div className="col-span-8 md:col-span-8">
              <div className="mb-2 block">
                <Label htmlFor="title" value="Title" />
              </div>
              <TextInput
                id="title"
                name="title"
                value={detailModalData.title}
                readOnly
              />
            </div>
            {role ? (
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="sender" value="Sender" />
                </div>
                <TextInput
                  id="sender"
                  name="senderName"
                  value={detailModalData.senderName || ""}
                  readOnly
                />
              </div>
            ) : null}
            {role ? (
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="admin" value="Admin" />
                </div>
                <TextInput
                  id="admin"
                  name="adminName"
                  value={detailModalData.adminName || ""}
                  readOnly
                />
              </div>
            ) : null}

            <div className="col-span-8 md:col-span-8 mb-2">
              <div className="mb-2 block">
                <Label htmlFor="content" value="Content" />
              </div>
              <Textarea
                id="content"
                name="content"
                className="p-2.5"
                rows={6}
                value={detailModalData.content}
                readOnly
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isNewShow} onClose={onCloseNewModal}>
        <Modal.Header className="p-4">Message</Modal.Header>
        <Modal.Body className="px-6 pt-4">
          <div className="grid grid-flow-row gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="title" value="Title" />
              </div>
              <TextInput
                id="title"
                name="title"
                value={newModalData.title}
                onChange={handleChangeNew}
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="receiver" value="To" />
              </div>
              <div className="bg-notification-multiselect dark:bg-notification-multiselect">
                <Select
                  id="receiver"
                  value={newModalData.receivers}
                  onChange={handleReceiverChange}
                  options={userList.map((item) => ({
                    value: item.id,
                    label: item.username,
                  }))}
                  isMultiple
                  isSearchable
                  primaryColor="accent"
                  isDisabled={newModalData.isToAll}
                />
              </div>
            </div>
            {role === 1 ? (
              <div className="flex items-center">
                <Checkbox
                  id="to-all"
                  name="isToAll"
                  value={newModalData.isToAll}
                  onClick={handleChangeNew}
                />
                <Label htmlFor="to-all">&nbsp;Send to All</Label>
              </div>
            ) : null}
            <div className="mb-2">
              <div className="mb-2 block">
                <Label htmlFor="content" value="Content" />
              </div>
              <Textarea
                id="content"
                name="content"
                className="p-2.5"
                rows={6}
                value={newModalData.content}
                onChange={handleChangeNew}
              />
            </div>
            <div>
              <Button
                color="blue"
                className="w-full"
                onClick={handleSendMessage}
              >
                Send
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        showDeleteModal={showDeleteModal}
        cancelDeleteModal={cancelDeleteModal}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default NotificationPage;
