import { Link } from "react-router-dom";

const Footer = () => {
    return (
      <footer className="bg-bg-default dark:bg-bg-dark text-text-default dark:text-text-dark text-sm p-8 mb-12">
        <div className="max-w-1320 mx-auto md:flex md:justify-between">
            <div className="mb-6 md:mb-0 md:w-1/3 text-base break-all p-1">
                <Link to="/" className="flex justify-center md:justify-start">
                    <img src="logo.png" alt="Logo" className="h-12" />
                </Link>
                <div className="mt-6 text-center md:text-left">
                    <strong>WWM VIP</strong>
                    <br />
                    <a href="mailto:support@wwmvip.com" className="font-bold text-leon-green" rel="noreferrer">support@wwmvip.com</a>
                    <br />
                    <span>+44 748 8882881</span>
                    <br />
                    <span>+1 509 7036738</span>
                    <br />
                    Our office address: Assheton Lane, Twycross, Atherstone, Warwickshire, England, CV9 3QQ
                </div>
                <div className="mt-6">
                WWM VIP is a trading name of West Wealth Management Ltd, which is incorporated in the United Kingdom, and registered by the Financial Services Authority, with an address at Assheton Lane, Twycross, Atherstone, Warwickshire, England, CV9 3QQ. WWM VIP is 100% owned by West Wealth Management Financial Services Ltd, a company incorporated in the UK. Over-the-counter derivatives are complex instruments and involve the use of leverage, which can lead to substantial returns but also requires careful consideration. It's important to ensure you understand how over-the-counter derivatives work and thoughtfully evaluate your financial situation before engaging in such investments. These investments may not be suitable for everyone.
                </div>
            </div>
            <div className="md:grid md:grid-cols-4 md:gap-20 grid grid-flow-row gap-4">
                <div className="mx-auto">
                    <a href="https://apps.apple.com/us/app/tradingview-track-all-markets/id1205990992" target="_blank" rel="noreferrer">
                        <img src="/img/appstore.svg" alt="appstore" className="text-center border p-2 w-36 md:w-32 rounded-lg h-auto mb-3" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.tradingview.tradingviewapp" target="_blank" rel="noreferrer">
                        <img src="/img/googleplay.svg" alt="appstore" className="text-center border p-2 w-36 md:w-32 rounded-lg h-auto" />
                    </a>
                </div>
                <div>
                    <h2 className="md:mb-6 mb-3 text-lg font-bold uppercase text-text-default dark:text-text-dark">Info</h2>
                    <ul className="font-medium text-base">
                        <li className="md:mb-4 mb-2">
                            <Link to="/packages" className="hover:underline">Packages</Link>
                        </li>
                        <li>
                            <Link to="/news" className="hover:underline">News</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="mb-6 text-lg font-bold uppercase text-text-default dark:text-text-dark">Markets</h2>
                    <ul className="font-medium text-base">
                        <li className="md:mb-4 mb-2">
                            <Link to="/cryptocurrency" className="hover:underline">Cryptocurrency</Link>
                        </li>
                        <li className="md:mb-4 mb-2">
                            <Link to="/etfs" className="hover:underline">ETFs</Link>
                        </li>
                        <li className="md:mb-4 mb-2">
                            <Link to="/forex" className="hover:underline">Forex</Link>
                        </li>
                        <li className="md:mb-4 mb-2">
                            <Link to="/metals" className="hover:underline">Metals</Link>
                        </li>
                        <li>
                            <Link to="/stocks" className="hover:underline">Stocks</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="mb-6 text-lg font-bold uppercase text-text-default dark:text-text-dark">About Us</h2>
                        <ul className="font-medium text-base">
                        <li className="md:mb-4 mb-2">
                            <Link to="/who-we-are" className="hover:underline">Who we are</Link>
                        </li>
                        <li>
                            <Link to="/legal-documents" className="hover:underline">Legal Documents</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
      </footer>
    )
  };
  
  export default Footer;