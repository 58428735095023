const CounterUp = () => {
    return (
      <section className="my-5 bg-header-dark p-12 text-text-default dark:text-text-dark">
        <div className="max-w-1320 mx-auto">
          <div className="flex flex-wrap -mx-4 gap-20 md:gap-0">
            <div className="w-full lg:w-1/3 px-4 mt-5 lg:mt-0 text-center">
              <i className="ri-discuss-line text-5xl mb-2"></i>
              <h1 className="counter text-5xl font-bold text-primary-button">$692M</h1>
              <h2 className="title-2 text-xl font-medium mt-2">Cumulative Profits</h2>
            </div>
            <div className="w-full lg:w-1/3 px-4 mt-5 lg:mt-0 text-center">
              <i className="ri-discuss-line text-5xl mb-2"></i>
              <h1 className="counter text-5xl font-bold text-primary-button">20 Years</h1>
              <h2 className="title-2 text-xl font-medium mt-2">Financial Brokers Experience</h2>
            </div>
            <div className="w-full lg:w-1/3 px-4 mt-5 lg:mt-0 text-center">
              <i className="ri-discuss-line text-5xl mb-2"></i>
              <h1 className="counter text-5xl font-bold text-primary-button">25K</h1>
              <h2 className="title-2 text-xl font-medium mt-2">Trades Processed per month</h2>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default CounterUp;