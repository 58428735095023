import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Home from "./pages/Home";
import PublicLayout from "./components/PublicLayout";
import PrivateRoute from "./components/PrivateRoute";
import PackagesPage from "./pages/Info/Packages";
import CryptoCurrency from "./pages/Markets/CryptoCurrency";
import Forex from "./pages/Markets/Forex";
import Metals from "./pages/Markets/Metals";
import ETFs from "./pages/Markets/ETFs";
import Stocks from "./pages/Markets/Stocks";
import ContactUs from "./pages/ContactUs";
import WhoWeAre from "./pages/AboutUs/WhoWeAre";
import LegalDocuments from "./pages/AboutUs/LegalDocuments";
import TechicalAnalysis from "./pages/TechicalAnalysis";
import NewsPage from "./pages/Info/News";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Documents from "./pages/Client/Documents";
import Contracts from "./pages/Client/Contracts";
// import Transactions from "./pages/Client/Transactions";
import NotFoundPage from "./pages/NotFoundPage";
import PersonalInformation from "./pages/Client/PersonalInformation";
import UserManagement from "./pages/admin/UserManagement";
import UserDetailPage from "./pages/admin/UserDetail";
import EmployeeDetailPage from "./pages/admin/EmployeeDetail";
import { ThemeProvider } from "./context/ThemeContext";
import ClientOfferDetailPage from "./pages/Client/ClientOfferDetailPage";
import EmployeeManagement from "./pages/admin/EmployeeManagement";
import NewUserPage from "./pages/admin/NewUserPage";
import NewEmployeePage from "./pages/admin/NewEmployeePage";
import OfferListPage from "./pages/Contract/OfferListPage";
import OfferDetailPage from "./pages/Contract/OfferDetailPage";
import ContractListPage from "./pages/Contract/ContractListPage";
import AdminContractDetailPage from "./pages/Contract/ContractDetailPage";
import TransactionListPage from "./pages/Transaction/TransactionListPage";
import AdminTransactionDetailPage from "./pages/Transaction/AdminTransactionDetail";
import ActiveContractPage from "./pages/Contract/ActiveContractPage";
import SecurityChangePage from "./pages/Client/SecurityChangePage";
import NotificationPage from "./pages/Notification";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ClientAccountPage from "./pages/Client/ClientAccountPage";
import NewAccountClientPage from "./pages/Client/NewAccountClientPage";
import NewAccountAdminPage from "./pages/admin/NewAccountAdminPage";
// import TransactionDetailPage from "./pages/Transaction/TransactionDetailPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router>
          <div className="min-h-screen bg-bg-default dark:bg-bg-dark text-text-default dark:text-text-dark transition-colors duration-300">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/packages"
                element={
                  <PublicLayout breadcrumb="Packages">
                    <PackagesPage />
                  </PublicLayout>
                }
              />
              <Route
                path="/news"
                element={
                  <PublicLayout breadcrumb="Packages">
                    <NewsPage />
                  </PublicLayout>
                }
              />
              <Route
                path="/cryptocurrency"
                element={
                  <PublicLayout breadcrumb="Cryptocurrency">
                    <CryptoCurrency />
                  </PublicLayout>
                }
              />
              <Route
                path="/forex"
                element={
                  <PublicLayout breadcrumb="Forex">
                    <Forex />
                  </PublicLayout>
                }
              />
              <Route
                path="/metals"
                element={
                  <PublicLayout breadcrumb="Metals">
                    <Metals />
                  </PublicLayout>
                }
              />
              <Route
                path="/etfs"
                element={
                  <PublicLayout breadcrumb="ETFs">
                    <ETFs />
                  </PublicLayout>
                }
              />
              <Route
                path="/stocks"
                element={
                  <PublicLayout breadcrumb="Stocks">
                    <Stocks />
                  </PublicLayout>
                }
              />
              <Route
                path="/contact"
                element={
                  <PublicLayout breadcrumb="Contact">
                    <ContactUs />
                  </PublicLayout>
                }
              />
              <Route
                path="/who-we-are"
                element={
                  <PublicLayout breadcrumb="Who We Are">
                    <WhoWeAre />
                  </PublicLayout>
                }
              />
              <Route
                path="/legal-documents"
                element={
                  <PublicLayout breadcrumb="Legal Documents">
                    <LegalDocuments />
                  </PublicLayout>
                }
              />
              <Route
                path="/technical-analysis"
                element={
                  <PublicLayout breadcrumb="Technical Analysis">
                    <TechicalAnalysis />
                  </PublicLayout>
                }
              />
              <Route
                path="/general"
                element={
                  <PrivateRoute>
                    <PersonalInformation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <ClientAccountPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/account/new"
                element={
                  <PrivateRoute>
                    <NewAccountClientPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/documents"
                element={
                  <PrivateRoute>
                    <Documents />
                  </PrivateRoute>
                }
              />
              <Route
                path="/contracts"
                element={
                  <PrivateRoute>
                    <Contracts />
                  </PrivateRoute>
                }
              />
              <Route
                path="/transactions"
                element={
                  <PrivateRoute>
                    <TransactionListPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/transactions/:id"
                element={
                  <PrivateRoute>
                    <AdminTransactionDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/offer/:id"
                element={
                  <PrivateRoute>
                    <ClientOfferDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/contracts/active/:id"
                element={
                  <PrivateRoute>
                    <ActiveContractPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/security"
                element={
                  <PrivateRoute>
                    <SecurityChangePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <PrivateRoute>
                    <NotificationPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/user-management/users"
                element={
                  <PrivateRoute>
                    <UserManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/user-management/employees"
                element={
                  <PrivateRoute>
                    <EmployeeManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/user-management/users/new"
                element={
                  <PrivateRoute>
                    <NewUserPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/user-management/employees/new"
                element={
                  <PrivateRoute>
                    <NewEmployeePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/user-management/users/:id"
                element={
                  <PrivateRoute>
                    <UserDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/user-management/employees/:id"
                element={
                  <PrivateRoute>
                    <EmployeeDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/contracts/offers"
                element={
                  <PrivateRoute>
                    <OfferListPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/contracts/offers/:id"
                element={
                  <PrivateRoute>
                    <OfferDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/contracts/offers/new"
                element={
                  <PrivateRoute>
                    <OfferDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/account/:id/new"
                element={
                  <PrivateRoute>
                    <NewAccountAdminPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/contracts/all"
                element={
                  <PrivateRoute>
                    <ContractListPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/contracts/:id"
                element={
                  <PrivateRoute>
                    <AdminContractDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/contracts/new"
                element={
                  <PrivateRoute>
                    <AdminContractDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/transactions"
                element={
                  <PrivateRoute>
                    <TransactionListPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/transactions/:id"
                element={
                  <PrivateRoute>
                    <AdminTransactionDetailPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/notifications"
                element={
                  <PrivateRoute>
                    <NotificationPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/security"
                element={
                  <PrivateRoute>
                    <SecurityChangePage />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          <ToastContainer />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
