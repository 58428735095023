const PaymentBlock = () => {
  return (
    <section className="my-5 bg-header-dark py-6 md:p-12 text-text-default dark:text-text-dark">
      <div className="max-w-1320 mx-auto px-4 flex items-center justify-center md:items-start">
        <div className="flex flex-wrap gap-2 md:gap-20 justify-center mt-8 md:mt-0">
          {/* <img src="/img/payment/visa.svg" alt="visa" className="payment-item" /> */}
          {/* <img src="/img/payment/mastercard.svg" alt="mastercard" className="payment-item" /> */}
          <img
            src="/img/payment/bitcoin.svg"
            alt="bitcoin"
            className="payment-item"
            style={{ height: "50px", objectFit: "cover" }}
          />
          <img
            src="/img/payment/tether-usdt.svg"
            alt="usdt"
            className="payment-item"
            style={{ height: "50px", objectFit: "cover" }}
          />
          <img
            src="/img/payment/xrp.svg"
            alt="xrp"
            className="payment-item"
            style={{ height: "50px", objectFit: "cover" }}
          />
          <img
            src="/img/payment/solana.svg"
            alt="solana"
            className="payment-item"
            style={{ height: "50px", objectFit: "cover" }}
          />
          <img
            src="/img/payment/visa.svg"
            alt="visa"
            className="payment-item"
            style={{ height: "50px", objectFit: "cover" }}
          />
        </div>
      </div>
    </section>
  );
};

export default PaymentBlock;
