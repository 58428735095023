import { useEffect, useRef } from "react";

const Metals = () => {
    const widgetContainerRef = useRef(null);
    const scriptAppendedRef = useRef(false);
  
    useEffect(() => {
      if (scriptAppendedRef.current) return;

      const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
        width: "100%",
        height: "300",
        symbolsGroups: [
            {
            name: "ETFs",
            originalName: "ETFs",
            symbols: [
                { name: "AMEX:SPY", displayName: "SPDR S&P 500" },
                { name: "NASDAQ:QQQ", displayName: "Invesco QQQ" },
                { name: "AMEX:IVV", displayName: "iShares Core S&P 500" },
                { name: "AMEX:VOO", displayName: "Vanguard S&P 500" },
                { name: "NASDAQ:VTI", displayName: "Vanguard Total Stock Market" },
                { name: "AMEX:VEA", displayName: "Vanguard FTSE Developed Markets" }
            ]
            }
        ],
        showSymbolLogo: true,
        colorTheme: "dark",
        isTransparent: false,
        displayMode: "adaptive",
        locale: "en",
        
        });
    
        widgetContainerRef.current.appendChild(script);
        scriptAppendedRef.current = true;
        }, []);

    return (
        <div className="text-base md:text-lg break-all">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">What are ETFs?</div>
                    <p className="mt-8">
                    Exchange-Traded Funds (ETFs) have been one of the most popular investment vehicles since their inception in the early 1990s. Besides mutual funds and individual stocks, trading and investing in ETFs is a globally popular way for investors to diversify their portfolios, gain exposure to different asset classes, and manage risk.
                    </p>
                    <p className="mt-4">
                    ETFs are investment funds that are traded on stock exchanges, similar to individual stocks. They typically hold assets such as stocks, commodities, or bonds and generally operate with an arbitrage mechanism designed to keep trading close to its net asset value, though deviations can occasionally occur. ETFs provide investors with the flexibility to buy and sell shares throughout the trading day at market prices, unlike mutual funds, which are traded only at the end of the trading day at the fund’s net asset value.
                    </p>
                </div>
            
            </div>

            <div className="mb-4">
                <div className="p-4">
                    <div className="tradingview-widget-container" ref={widgetContainerRef}>
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-4"></div>
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">How to Trade ETFs</div>
                    <p className="mt-8">
                    Trade ETFs with a global network of Exchanges, Brokers, Institutional Investors, and Retail Traders. Our platform brings extensive expertise to our clients.
                    </p>
                    <p className="mt-4">
                    Trade ETFs with a global network of Exchanges, Brokers, Institutional Investors, and Retail Traders. Our platform brings extensive expertise to our clients. ETF traders will have access to diversified portfolios, real-time market data, advanced analytical tools, account management, tight spreads, and comprehensive research.
                    </p>
                    <p className="mt-4">
                    Using Our Trading Platform technology, traders and partners will have access to a wide range of ETF trading products in addition to many other products in Forex, commodities, indices, and cryptocurrencies CFD’s.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default Metals;