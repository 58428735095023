import { LOGOUT, CHECKTOKEN } from '../actions/types'; // Adjust the import path

const authMiddleware = store => next => action => {
  if (action.type === CHECKTOKEN) {
    const token = localStorage.getItem('token');
    if (!token) {
      store.dispatch({ type: LOGOUT });
    } else {
      const tokenExpiry = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
      if (tokenExpiry < new Date()) {
        store.dispatch({ type: LOGOUT });
      }
    }
  }
  return next(action);
};

export default authMiddleware;