import axios from "axios";
import { LOGOUT } from "../actions/types";
import store from "../store";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: `https://${process.env.REACT_APP_SERVER_DOMAIN_NAME}`,
  headers: {
    "Content-Type": "application/json",
  },
});

let toastDisplayed = false;

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.status === -1
    ) {
      store.dispatch({ type: LOGOUT });
      const state = store.getState();
      const isAuth = state.auth.isAuthenticated;

      if (!isAuth && !toastDisplayed) {
        toast.warn("Token is expired", { autoClose: 3000 });
        toastDisplayed = true;
      }
    }
    return Promise.reject(error);
  }
);

store.subscribe(() => {
  const state = store.getState();
  const isAuth = state.auth.isAuthenticated;
  if (!isAuth) {
    toastDisplayed = false;
  }
});

export default api;
