const LegalDocuments = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 mb-4 gap-6 pt-6">
            <div className="flex flex-col bg-secondary dark:bg-secondary-dark p-6 items-center rounded-xl gap-6">
                <img src="https://leonllp.org/wp-content/themes/investpole/img/documents-icon.svg" alt="Alt" className="size-16" />
                <div className="text-2xl font-bold">Customer Agreement</div>
                <a href="/download/CustomerAgreement.pdf" download="" className="bg-primary-button text-white font-bold py-4 px-8 rounded-full"><span>Download</span></a>
            </div>
        
            <div className="flex flex-col bg-secondary dark:bg-secondary-dark p-6 items-center rounded-xl gap-6">
                <img src="https://leonllp.org/wp-content/themes/investpole/img/documents-icon.svg" alt="Alt" className="size-16" />
                <div className="text-2xl font-bold">Terms of Business</div>
                <a href="/download/TermsofBusiness.pdf" download="" className="bg-primary-button text-white font-bold py-4 px-8 rounded-full"><span>Download</span></a>
            </div>
        
            <div className="flex flex-col bg-secondary dark:bg-secondary-dark p-6 items-center rounded-xl gap-6">
                <img src="https://leonllp.org/wp-content/themes/investpole/img/documents-icon.svg" alt="Alt" className="size-16" />
                <div className="text-2xl font-bold">Privacy Policy</div>
                <a href="/download/PrivacyPolicy.pdf" download="" className="bg-primary-button text-white font-bold py-4 px-8 rounded-full"><span>Download</span></a>
            </div>
        </div>
    )
}


export default LegalDocuments;