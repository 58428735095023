import React, { useEffect, useRef } from 'react';

const Forex = () => {
  const widgetContainerRef = useRef(null);
  const scriptAppendedRef = useRef(false);
  
  useEffect(() => {
    if (scriptAppendedRef.current) return;

    const script = document.createElement('script');
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "300",
      symbolsGroups: [
        {
          name: "Forex",
          originalName: "Forex",
          symbols: [
            { name: "FX:EURUSD" },
            { name: "FX:GBPUSD" },
            { name: "FX:USDJPY" },
            { name: "FX:USDCHF" },
            { name: "FX:AUDUSD" },
            { name: "FX:USDCAD" }
          ]
        }
      ],
      showSymbolLogo: true,
      colorTheme: "dark",
      isTransparent: false,
      displayMode: "adaptive",
      locale: "en"
    });

    widgetContainerRef.current.appendChild(script);
    scriptAppendedRef.current = true;
  }, []);

  return (
    <div className="text-base md:text-lg break-all">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="p-4">
            <div className="text-2xl md:text-3xl font-bold mt-2">How to Trade Forex</div>
            <p className="mt-8">
            Forex or Foreign Exchange market is open 24 hours a day, 5 days a week. Forex is considered to be the largest and most liquid trading market in the world with daily volumes exceeding $4 trillion surpassing any other exchange-based market in the world today.
            </p>
            <p className="mt-4">
            Trading Foreign Exchange involves trading one currency pair against another, predicting that one currency will rise or fall against another. Currencies are traded in pairs, like Euro versus US Dollar (EUR/USD).
            </p>
            <p className="mt-8">
            Our Platform is the latest and most advanced online Forex CFD’s trading platform. Trading using our Platform platforms gives traders full access to the global derivatives products in financial markets including Foreign Exchange, metals, commodities, futures and indices.
            </p>
            <p className="mt-4">
            It’s this exchange rate that determines how much of one currency you get in exchange for another, e.g. how many pounds you get for your euros.
            </p>
        </div>
      
      </div>

      <div className="mb-4">
        <div className="p-4">
            <div className="market-data-widget-container">
            <div ref={widgetContainerRef}></div>
            </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="p-4"></div>
        <div className="p-4">
            <div className="text-2xl md:text-3xl font-bold mt-2">How to Trade Forex</div>
            <p className="mt-8">
            The value of each currency depends on the supply and demand for it. Therefore, this determines the “Exchange Rate” between the two currencies, which is continually fluctuating. The Exchange Rate itself is basically the difference between the value of one currency against another.
            </p>
            <p className="mt-4">
            It’s this exchange rate that determines how much of one currency you get in exchange for another, e.g. how many pounds you get for your euros.
            </p>
        </div>
      </div>
    </div>
  )
}


export default Forex;