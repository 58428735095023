import OverviewHeader from "./OverviewHeader";
import Header from "../components/Header";
import PaymentBlock from "../components/PaymentBlock";
import Footer from "../components/Footer";
import FloatingButton from "./FloatingButton";

const PublicLayout = ({ breadcrumb, children }) => {
  return (
    <div>
      <Header />
      <div className="mt-20">
        <OverviewHeader breadcrumb={breadcrumb} />
        <div className="max-w-1320 mx-auto px-4 mt-8 mb-24">{children}</div>
      </div>
      <PaymentBlock />
      <Footer />
      <FloatingButton />
    </div>
  );
};

export default PublicLayout;
