import { Link } from "react-router-dom";

const OverviewHeader = ({breadcrumb}) => {
    return (
        <div className='bg-secondary dark:bg-secondary-dark'>
            <div className="max-w-1320 mx-auto px-6 pt-6 pb-9">
                <p className="text-sm"><Link to='/' className="hover:underline">HOME</Link> / {breadcrumb.toUpperCase()}</p>
                <div className="text-4xl font-bold mt-2">{breadcrumb}</div>
            </div>
        </div>
    )
}

export default OverviewHeader;