import React, { useState } from "react";
import { Card, Table, Select, Modal, Button, Textarea } from "flowbite-react";
import { HiTrash, HiPencil, HiEye } from "react-icons/hi";
import { VscSend } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import api from "../utils/api";
import { toast } from "react-toastify";

const ContractListCard = ({
  role,
  tableData,
  onChangeStatus,
  confirmDelete,
}) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDel, setSeletedDel] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [comment, setComment] = useState("");

  const onCloseModal = () => {
    setIsShow(false);
  };

  const handleSendComment = async () => {
    try {
      console.log(selectedUser);
      await api.patch(`/api/v1/contracts/comment/${selectedUser.id}`, {
        comment: comment,
        userId: selectedUser.userId,
      });
      setIsShow(false);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDeleteModal = async () => {
    try {
      await confirmDelete(selectedDel);
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Card className="overflow-x-auto">
        <h3 className="text-xl font-semibold dark:text-white">All Contracts</h3>
        <Table>
          <Table.Head>
            <Table.HeadCell>No</Table.HeadCell>
            <Table.HeadCell>Title</Table.HeadCell>
            <Table.HeadCell>User</Table.HeadCell>
            <Table.HeadCell>Admin</Table.HeadCell>
            <Table.HeadCell>Date</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            {role === 0 ? null : <Table.HeadCell>Action</Table.HeadCell>}
          </Table.Head>
          <Table.Body className="divide-y">
            {tableData.map((row, index) => (
              <Table.Row
                key={index + 1}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {index + 1}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.offerTitle}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.userName}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.adminName}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.createDateTime}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  <Select
                    name="status"
                    value={row.status}
                    onChange={(e) => onChangeStatus(row.id, e.target.value)}
                    required
                    className="w-full flex items-center"
                  >
                    <option value={0}>Pending</option>
                    <option value={1}>Approved</option>
                    <option value={-1}>Rejected</option>
                  </Select>
                </Table.Cell>
                {role === 0 ? null : (
                  <Table.Cell className="space-x-3 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <button
                      onClick={() => navigate(`/admin/contracts/${row.id}`)}
                    >
                      <HiPencil className="h-5 w-5 text-blue-500 cursor-pointer" />
                    </button>
                    <button
                      onClick={() => {
                        setIsShow(true);
                        setSelectedUser(row);
                      }}
                    >
                      <VscSend className="h-5 w-5 text-green-500 cursor-pointer" />
                    </button>
                    <button>
                      <HiTrash
                        className="h-5 w-5 text-red-500 cursor-pointer"
                        onClick={() => {
                          setSeletedDel(row.id);
                          setShowDeleteModal(true);
                        }}
                      />
                    </button>
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {tableData.length === 0 ? (
          <div className="text-lg p-4 text-center">There is no contract.</div>
        ) : null}
        <Modal show={isShow} size="md" onClose={onCloseModal}>
          <Modal.Header className="p-4">
            Talk to {selectedUser.userName}
          </Modal.Header>
          <Modal.Body className="px-6 pt-4">
            <Textarea
              placeholder="Placeholder"
              id="description"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="p-2.5"
              rows={6}
            />
            <Button
              color="blue"
              className="w-full mt-4"
              onClick={handleSendComment}
            >
              Submit
            </Button>
          </Modal.Body>
        </Modal>
      </Card>
      <ConfirmModal
        showDeleteModal={showDeleteModal}
        cancelDeleteModal={cancelDeleteModal}
        confirmDelete={confirmDeleteModal}
      />
    </div>
  );
};

export default ContractListCard;
