import { Button } from "flowbite-react";
import api from "../../utils/api";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ContractListCard from "../../components/ContractListCard";

const ContractListPage = () => {
  const [tableData, setTableData] = useState([]);
  const role = useSelector((s) => s.auth.user.role);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = (await api.get("/api/v1/contracts/all")).data;
        setTableData(response);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  const confirmDelete = async (selectedDel) => {
    try {
      const response = (await api.delete(`/api/v1/contracts/${selectedDel}`))
        .data;
      setTableData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeStatus = async (id, selected) => {
    try {
      await api.patch(`/api/v1/contracts/status/${id}`, {
        status: selected,
        prev: tableData.find((item) => item.id === id)?.status,
        userId: tableData.find((item) => item.id === id)?.userId,
      });
      const data = tableData.map((item) =>
        item.id === id ? { ...item, status: selected } : item
      );
      setTableData(data);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Contracts
        </h1>
        {role === 0 ? null : (
          <div className="flex gap-2">
            <Link to="/admin/contracts/new">
              <Button color="blue">Add Contract</Button>
            </Link>
          </div>
        )}
      </div>
      <ContractListCard
        role={role}
        tableData={tableData}
        onChangeStatus={onChangeStatus}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default ContractListPage;
