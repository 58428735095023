exports.convertType = (type) => {
  if (type === "saving") {
    return "Saving";
  }
  if (type === "credit") {
    return "Credit";
  }
  if (type === "current") {
    return "Current";
  }
  if (type === "contract") {
    return "Contract";
  }
};
