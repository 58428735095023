import {
  Button,
  Card,
  Label,
  TextInput,
  Textarea,
  Modal,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { FaAngleLeft } from "react-icons/fa";
import { toast } from "react-toastify";

const ClientOfferDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    lockPeriod: 0,
    price: 0,
    profitPercentage: 0,
    description: "",
    comment: "",
    paymentLink: "",
  });

  const [isShow, setIsShow] = useState(false);

  const onCloseModal = () => {
    setIsShow(false);
  };

  const onShowTalkExpertModal = () => {
    setIsShow(true);
  };

  const handleInvestBtn = async () => {
    try {
      window.open(formData.paymentLink, "_blank");
      await api.post("/api/v1/contracts/invest", {
        offerId: id,
        status: 0,
        comment: "",
        title: formData.title,
      });
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const handleTalkToExpert = async () => {
    try {
      await api.post("/api/v1/notifications/expert", {
        ...formData,
        id,
      });
      setFormData({ ...formData, comment: "" });
      setIsShow(false);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/offers/id/${id}`);
        const data = response.data;

        setFormData({
          title: data.title || "",
          lockPeriod: data.lockPeriod || 0,
          price: data.price || 0,
          profitPercentage: data.profitPercentage || 0,
          description: data.description || "",
          comment: "",
          paymentLink: data.paymentLink || "",
        });
      } catch (err) {
        console.log(err);
      }
    }

    if (id !== undefined) fetchData();
  }, [id]);

  return (
    <div className="p-0">
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate("/contracts")}
          />
          Offer Detail
        </h1>
      </div>
      <div className="grid grid-flow-cols gap-4">
        <Card>
          <h3 className="text-xl font-semibold dark:text-white">Offer</h3>
          <div className="px-0 break-all xl:break-normal xl:px-4 grid grid-flow-cols gap-4">
            <div className="grid grid-cols-8 gap-6 mb-4">
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="title" value="Title" />
                </div>
                <TextInput
                  id="title"
                  name="title"
                  defaultValue={formData.title}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="lock-period" value="Lock Period" />
                </div>
                <TextInput
                  id="lock-period"
                  name="lockPeriod"
                  type="number"
                  value={formData.lockPeriod}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="price" value="Price" />
                </div>
                <TextInput
                  id="price"
                  name="price"
                  type="number"
                  value={formData.price}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="profit-percentage"
                    value="Profit Percentage"
                  />
                </div>
                <TextInput
                  id="profit-percentage"
                  name="profitPercentage"
                  type="number"
                  value={formData.profitPercentage}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-8">
                <div className="mb-2 block">
                  <Label htmlFor="payment-link" value="Payment Link" />
                </div>
                <TextInput
                  id="payment-link"
                  name="paymentLink"
                  value={formData.paymentLink}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div className="col-span-8">
                <div className="mb-2 block">
                  <Label htmlFor="description" value="Description" />
                </div>
                <Textarea
                  id="description"
                  name="description"
                  defaultValue={formData.description}
                  className="p-2.5"
                  rows={4}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-4">
                <Button
                  color="blue"
                  className="w-full"
                  onClick={handleInvestBtn}
                >
                  Invest Now
                </Button>
              </div>
              <div className="col-span-8 md:col-span-4">
                <Button
                  color="blue"
                  className="w-full"
                  onClick={() => onShowTalkExpertModal()}
                >
                  Talk to Expert
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Modal show={isShow} size="md" onClose={onCloseModal}>
        <Modal.Header className="p-4">Talk to Expert</Modal.Header>
        <Modal.Body className="px-6 pt-4">
          <Textarea
            placeholder="Please leave a message."
            id="description"
            value={formData.comment}
            onChange={(e) =>
              setFormData({ ...formData, comment: e.target.value })
            }
            className="p-2.5"
            rows={6}
          />
          <Button
            color="blue"
            className="w-full mt-4"
            onClick={() => handleTalkToExpert()}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClientOfferDetailPage;
