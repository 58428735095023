import { useEffect, useRef } from "react";

const Metals = () => {
    const widgetContainerRef = useRef(null);
    const scriptAppendedRef = useRef(false);
  
    useEffect(() => {
      if (scriptAppendedRef.current) return;
  
      const script = document.createElement('script');
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        width: "100%",
        height: "100%",
        symbolsGroups: [
          {
            name: "Futures",
            originalName: "Futures",
            symbols: [
              {
                name: "COMEX:GC1!",
                displayName: "Gold"
              },
              {
                name: "",
                displayName: "Silver"
              }
            ]
          }
        ],
        showSymbolLogo: true,
        colorTheme: "dark",
        isTransparent: false,
        displayMode: "adaptive",
        locale: "en"
      });
  
      widgetContainerRef.current.appendChild(script);
      scriptAppendedRef.current = true;
    }, []);

    return (
        <div className="text-base md:text-lg break-all">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">What are Precious Metals?</div>
                    <p className="mt-8">
                    Precious metals have been some of the most popular hard commodities to trade since the 1970s. Besides Foreign Exchange trading, trading and speculating on gold, silver and other precious metals is a worldwide popular way of hedging and managing risks during high inflation times or during global economic or political tensions.
                    </p>
                    <p className="mt-4">
                    Precious metals have been some of the most popular hard commodities to trade since the 1970s. Besides Foreign Exchange trading, trading and speculating on gold, silver and other precious metals is a worldwide popular way of hedging and managing risks during high inflation times or during global economic or political tensions.
                    </p>
                    <p className="mt-4">
                    Precious metals can be traded in both directions. If a metal’s price is expected to move upwards, traders can enter by purchasing a futures contract and exit the trade by selling it. Similarly, if there is an anticipation of a downward movement, traders can enter in a sell futures contract and exit the trade by buying this contract back again.
                    </p>
                </div>
            
            </div>

            <div className="mb-4">
                <div className="p-4">
                    <div className="tradingview-widget-container" ref={widgetContainerRef}>
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-4"></div>
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">How to Trade Precious Metals</div>
                    <p className="mt-8">
                    Trade Precious Metals with a worldwide network of Precious Metals Dealers, Producers, Consumers, and Speculators. Brings a breadth of experience to our clients.
                    </p>
                    <p className="mt-4">
                    Trade Precious Metals with a worldwide network of Precious Metals Dealers, Producers, Consumers, and Speculators. Brings a breadth of experience to our clients. Metals traders will have access to position tracking, account management, narrow spreads and various analysis.
                    </p>
                    <p className="mt-4">
                    Using Our Trading Platform technology, traders and partners will have an access to a wide-range of precious metals trading products in addition to many other products in Forex, commodities, indices and cryptocurrencies CFD’s
                    </p>
                </div>
            </div>
        </div>
    )
}


export default Metals;