import React from 'react';
import PriceList from '../../components/Home/PriceList';

const qalist = [
    {
        question: "How can I select a broker that suits my trading needs?",
        answer: `To choose a broker aligned with your needs, consider their regulatory status, available trading
                instruments, platform features, and spreads. Research and compare these factors to find a
                broker that complements your trading style and goals.`
    },
    {
        question: "Why is broker regulation important in forex trading",
        answer: `Broker regulation ensures a safe trading environment by enforcing ethical practices, financial
                transparency, and the protection of traders' funds. Regulated brokers are held accountable by
                regulatory authorities, reducing the risks associated with fraudulent activities and enhancing
                traders' confidence.`
    },
    {
        question: "What are spreads, and how do they impact trading costs?",
        answer: `Spreads are the difference between buying and selling prices for a currency pair. They impact
                trading costs, as wider spreads increase costs while tighter spreads result in lower expenses.
                Choosing a broker with competitive spreads can contribute to more cost-effective trading.`
    },
    {
        question: "Can you explain the different types of trading platforms offered by brokers?",
        answer: `Forex brokers provide various trading platforms, such as MetaTrader 4 (MT4) and MetaTrader 5
                (MT5), known for their user-friendly interfaces and powerful analysis tools. Some brokers offer
                proprietary platforms with unique features. When choosing a platform, consider its ease of use,
                available technical indicators, charting options, and compatibility with your trading strategy`
    },
    {
        question: "Do brokers charge commissions, and how are they calculated?",
        answer: `Yes, brokers may charge commissions on trades. Commissions can be calculated as a fixed fee
                per trade or a percentage of the trade's value. Some brokers offer commission-free accounts
                with slightly wider spreads, while others have lower spreads but charge a commission. Consider
                your trading frequency and preferences to decide which commission structure suits you better.`
    },
    {
        question: "What's the minimum deposit required to start trading with a broker?",
        answer: `Minimum deposits vary among brokers and account types. They range from a few dollars to a
                few hundred or more. The minimum deposit is the initial amount needed to open a trading
                account and access the broker's services. While some brokers offer lower minimum deposits for
                beginners, others might require higher deposits for advanced features. It's important to align the
                deposit with your budget and trading goals.`
    }
]

const Packages = () => {
    return (
        <div className='pt-6'>
            <PriceList />
            <div className='mt-20'>
                <div className='text-4xl font-bold text-center'>FAQ</div>
                <div className="relative w-full px-6 pb-8 mt-8 sm:rounded-lg">
                    <div className="mx-auto">
                        <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
                            {
                                qalist.map(({question, answer}, index) => { 
                                    return(
                                    <div className="py-5" key={index}>
                                        <details className="group">
                                            <summary className="flex cursor-pointer list-none items-center justify-between font-medium text-lg">
                                                <span>{question}</span>
                                                <span className="transition group-open:rotate-180">
                                                <svg
                                                    fill="none"
                                                    height="24"
                                                    shapeRendering="geometricPrecision"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="1.5"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                >
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                                </span>
                                            </summary>
                                            <p className="group-open:animate-fadeIn mt-3">
                                                {answer}
                                            </p>
                                        </details>
                                    </div>
                                )})
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Packages;