import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import PrivateLayout from "./PrivateLayout";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isVerified = useSelector((state) => state.auth.user?.isVerified);
  const role = useSelector((state) => state.auth.user?.role);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  // if (!isVerified) {
  //   return <Navigate to="/verify-email" />;
  // }

  if (role && !location.pathname.startsWith("/admin")) {
    return <Navigate to="/admin/user-management/users" />;
  }

  if (!role && location.pathname.startsWith("/admin")) {
    return <Navigate to="/account" />;
  }

  return <PrivateLayout>{children}</PrivateLayout>;
};

export default PrivateRoute;
