import { Button, Card, Table } from "flowbite-react";
import api from "../../utils/api";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiPencil, HiTrash } from "react-icons/hi";
import ConfirmModal from "../../components/ConfirmModal";

const OfferListPage = () => {
  const [tableData, setTableData] = useState([]);
  const role = useSelector((s) => s.auth.user.role);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDel, setSeletedDel] = useState(0);

  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    try {
      const response = (await api.delete(`/api/v1/offers/${selectedDel}`)).data;
      setTableData(response);
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = (await api.get("/api/v1/offers/all")).data;
        setTableData(response);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Hot Offers
        </h1>
        {role === 1 ? (
          <div className="flex gap-2">
            <Link to="/admin/contracts/offers/new">
              <Button color="blue">Add Offer</Button>
            </Link>
          </div>
        ) : null}
      </div>
      <Card className="overflow-x-auto">
        <h3 className="text-xl font-semibold dark:text-white">All Offers</h3>
        <Table>
          <Table.Head>
            <Table.HeadCell>No</Table.HeadCell>
            <Table.HeadCell>Title</Table.HeadCell>
            <Table.HeadCell>Price</Table.HeadCell>
            <Table.HeadCell>Profit</Table.HeadCell>
            <Table.HeadCell>Lock Period</Table.HeadCell>
            <Table.HeadCell>Created Datetime</Table.HeadCell>
            {role === 1 ? <Table.HeadCell>Action</Table.HeadCell> : null}
          </Table.Head>
          <Table.Body className="divide-y">
            {tableData.map((row, index) => (
              <Table.Row
                key={index + 1}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {index + 1}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.title}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.price}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.profitPercentage}%
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.lockPeriod}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.createDateTime}
                </Table.Cell>
                {role === 1 ? (
                  <Table.Cell className="flex space-x-3 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <Link to={`/admin/contracts/offers/${row.id}`}>
                      <HiPencil className="h-5 w-5 text-blue-500 cursor-pointer" />
                    </Link>

                    <HiTrash
                      className="h-5 w-5 text-red-500 cursor-pointer"
                      onClick={() => {
                        setSeletedDel(row.id);
                        setShowDeleteModal(true);
                      }}
                    />
                  </Table.Cell>
                ) : null}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
      <ConfirmModal
        showDeleteModal={showDeleteModal}
        cancelDeleteModal={cancelDeleteModal}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default OfferListPage;
