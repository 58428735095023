import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownInfoOpen, setIsDropdownInfoOpen] = useState(false);
  const [isDropdownMarketsOpen, setIsDropdownMarketsOpen] = useState(false);
  const [isDropdownAboutUsOpen, setIsDropdownAboutUsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const onClickDropdownInfoOpen = () => {
    setIsDropdownInfoOpen(!isDropdownInfoOpen);
    setIsDropdownMarketsOpen(false);
    setIsDropdownAboutUsOpen(false);
  };

  const onClickDropdownMarktesOpen = () => {
    setIsDropdownInfoOpen(false);
    setIsDropdownAboutUsOpen(false);
    setIsDropdownMarketsOpen(!isDropdownMarketsOpen);
  };

  const onClickDropdownAboutUsOpen = () => {
    setIsDropdownInfoOpen(false);
    setIsDropdownMarketsOpen(false);
    setIsDropdownAboutUsOpen(!isDropdownAboutUsOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeAllDropdowns = () => {
    setIsMobileMenuOpen(false);
    setIsDropdownInfoOpen(false);
    setIsDropdownMarketsOpen(false);
    setIsDropdownAboutUsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`fixed top-0 left-0 w-full z-50 ${isScrolled || isMobileMenuOpen ? (isDarkMode ? 'bg-header-dark' : 'bg-header-default') : 'bg-transparent'} transition-all duration-300`}>
      <div className='container max-w-1320 mx-auto flex justify-between items-center py-4 px-6'>
        <div className='text-lg font-bold'>
          <Link to='/' onClick={closeAllDropdowns}>
            <img src='logo.png' alt='Logo' className='h-12' />
          </Link>
        </div>
        <button className='lg:hidden text-2xl' onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <nav className={`lg:flex items-center space-x-12 text-lg font-medium hidden lg:block`}>
          <div className='relative'>
            <button onClick={onClickDropdownInfoOpen} className='flex items-center space-x-1'>
              <span className={isDarkMode ? 'text-text-dark' : 'text-text-default'}>Info</span>
              <FaChevronDown />
            </button>
            {isDropdownInfoOpen && (
              <div className={`absolute top-8 left-0 mt-2 w-48 rounded-md shadow-lg ${isDarkMode ? 'bg-header-dark text-text-dark' : 'bg-header-default text-text-default'}`}>
                <Link to="/packages" className='block px-4 py-2' onClick={closeAllDropdowns}>Packages</Link>
                <Link to="/news" className='block px-4 py-2' onClick={closeAllDropdowns}>News</Link>
              </div>
            )}
          </div>
          <div className='relative'>
            <button onClick={onClickDropdownMarktesOpen} className='flex items-center space-x-1'>
              <span className={isDarkMode ? 'text-text-dark' : 'text-text-default'}>Markets</span>
              <FaChevronDown />
            </button>
            {isDropdownMarketsOpen && (
              <div className={`absolute top-8 left-0 mt-2 w-48 rounded-md shadow-lg ${isDarkMode ? 'bg-header-dark text-text-dark' : 'bg-header-default text-text-default'}`}>
                <Link to="/cryptocurrency" className='block px-4 py-2' onClick={closeAllDropdowns}>Cryptocurrency</Link>
                <Link to="/etfs" className='block px-4 py-2' onClick={closeAllDropdowns}>ETFs</Link>
                <Link to="/forex" className='block px-4 py-2' onClick={closeAllDropdowns}>Forex</Link>
                <Link to="/metals" className='block px-4 py-2' onClick={closeAllDropdowns}>Metals</Link>
                <Link to="/stocks" className='block px-4 py-2' onClick={closeAllDropdowns}>Stocks</Link>
              </div>
            )}
          </div>
          <div className='relative'>
            <button onClick={onClickDropdownAboutUsOpen} className='flex items-center space-x-1'>
              <span className={isDarkMode ? 'text-text-dark' : 'text-text-default'}>About Us</span>
              <FaChevronDown />
            </button>
            {isDropdownAboutUsOpen && (
              <div className={`absolute top-8 left-0 mt-2 w-48 rounded-md shadow-lg ${isDarkMode ? 'bg-header-dark text-text-dark' : 'bg-header-default text-text-default'}`}>
                <Link to="/who-we-are" className='block px-4 py-2' onClick={closeAllDropdowns}>Who we are</Link>
                <Link to="/legal-documents" className='block px-4 py-2' onClick={closeAllDropdowns}>Legal Documents</Link>
              </div>
            )}
          </div>
          <Link to="/contact" className={isDarkMode ? 'text-text-dark' : 'text-text-default'}>Contact Us</Link>
          <Link to="/signin" className='text-leon-green'>Signin</Link>
          <Link to="/signup" className='text-leon-green'>Signup</Link>
        </nav>
      </div>
      {isMobileMenuOpen && (
        <div className='lg:hidden block'>
          <nav className='flex flex-col items-start space-y-4 py-4 px-6'>
            <div className='relative'>
              <button onClick={onClickDropdownInfoOpen} className='flex items-center space-x-1'>
                <span className={isDarkMode ? 'text-text-dark' : 'text-text-default'}>Info</span>
                <FaChevronDown />
              </button>
              {isDropdownInfoOpen && (
                <div className={`mt-2 w-48 rounded-md ${isDarkMode ? 'bg-header-dark text-text-dark' : 'bg-header-default text-text-default'}`}>
                  <Link to="/packages" className='block px-4 py-2' onClick={closeAllDropdowns}>Packages</Link>
                  <Link to="/news" className='block px-4 py-2' onClick={closeAllDropdowns}>News</Link>
                </div>
              )}
            </div>
            <div className='relative'>
              <button onClick={onClickDropdownMarktesOpen} className='flex items-center space-x-1'>
                <span className={isDarkMode ? 'text-text-dark' : 'text-text-default'}>Markets</span>
                <FaChevronDown />
              </button>
              {isDropdownMarketsOpen && (
                <div className={`mt-2 w-48 rounded-md ${isDarkMode ? 'bg-header-dark text-text-dark' : 'bg-header-default text-text-default'}`}>
                  <Link to="/cryptocurrency" className='block px-4 py-2' onClick={closeAllDropdowns}>Cryptocurrency</Link>
                  <Link to="/etfs" className='block px-4 py-2' onClick={closeAllDropdowns}>ETFs</Link>
                  <Link to="/forex" className='block px-4 py-2' onClick={closeAllDropdowns}>Forex</Link>
                  <Link to="/metals" className='block px-4 py-2' onClick={closeAllDropdowns}>Metals</Link>
                  <Link to="/stocks" className='block px-4 py-2' onClick={closeAllDropdowns}>Stocks</Link>
                </div>
              )}
            </div>
            <div className='relative'>
              <button onClick={onClickDropdownAboutUsOpen} className='flex items-center space-x-1'>
                <span className={isDarkMode ? 'text-text-dark' : 'text-text-default'}>About Us</span>
                <FaChevronDown />
              </button>
              {isDropdownAboutUsOpen && (
                <div className={`mt-2 w-48 rounded-md ${isDarkMode ? 'bg-header-dark text-text-dark' : 'bg-header-default text-text-default'}`}>
                  <Link to="/who-we-are" className='block px-4 py-2' onClick={closeAllDropdowns}>Who we are</Link>
                  <Link to="/legal-documents" className='block px-4 py-2' onClick={closeAllDropdowns}>Legal Documents</Link>
                </div>
              )}
            </div>
            <Link to="/contact" className={isDarkMode ? 'text-text-dark' : 'text-text-default'} onClick={closeAllDropdowns}>Contact Us</Link>
            <Link to="/signin" className='text-leon-green' onClick={closeAllDropdowns}>Signin</Link>
            <Link to="/signup" className='text-leon-green' onClick={closeAllDropdowns}>Signup</Link>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;