const TechnicalAnalysis = () => {
  return (
    <div className="container mx-auto p-0 break-all">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="row-span-1">
          <div className="text-2xl font-bold">
            Top Forex Indicators Things to Know
          </div>
          <p className="mt-6">
            Since indicators are such an important component of Forex trading,
            every technical and fundamental analyst should be familiar with the
            structure and applications of the most often used mt5 indicators.
          </p>
        </div>
        <div className="row-span-1"></div>
        <div className="row-span-1">
          <div className="text-2xl font-bold">Moving Averages (MA)</div>
          <p className="mt-6">
            Every trader should be familiar with the concept of moving average.
            The currency market is driven by central banks and international
            corporations. As a result, it’s critical to comprehend what’s going
            on at the macro level.
          </p>
          <p className="mt-4">
            The moving average is the average price of the previous number of
            candles, which represents the price’s overall emotion.
          </p>
          <p className="mt-4">
            If the price is trading above the moving average, it indicates that
            the market is dominated by buyers. If the price is trading below the
            moving average, however, it indicates that sellers are in control of
            the market. As a result, if the price is above the moving average,
            you should focus your trading approach on buying trades. It is one
            of the most important forex indicators for a trader to understand.
          </p>
          <p className="mt-4">
            In addition, the simple moving average shows the average price of
            the previous number of candles, which aids traders in understanding
            the market environment.
          </p>
          <p className="mt-4">
            The exponential moving average, on the other hand, focuses on the
            most recent movement and assists traders in entering a trade.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a1.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>
        <div className="row-span-1 mt-6 md:mt-8">
          <div className="text-2xl font-bold">
            The Relative Strength Index (RSI)
          </div>
          <p className="mt-6">
            The relative strength index fluctuates between 0 and 100. This
            indicator shows where the price is most likely to turn around
          </p>
          <p className="mt-4">
            When the price climbs over the 70 levels in an uptrend, it suggests
            a negative market reversal. Similarly, in a downtrend, if the price
            falls below the 30 level, it suggests a bullish market turnaround.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a2.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>
        <div className="row-span-1">
          <div className="text-2xl font-bold">
            MACD (Moving Average Convergence Diver)
          </div>
          <p className="mt-6">
            A histogram and an exponential moving average make up the MACD
            trading indicator. The primary goal of this indicator is to
            calculate price divergence. The regular divergence between MACD and
            price suggests a market reversal, but the hidden divergence
            indicates that the market will continue.
          </p>
          <p className="mt-4">
            It is frequently used by traders as a major indicator when
            developing a trading strategy. This signal, on the other hand, might
            be used to identify a potential market reversal or continuation
            point. As a result, you can use a trading strategy based on other
            mt5 indicators to enter the trade.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a3.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>
        <div className="row-span-1">
          <div className="text-2xl font-bold">Bollinger Bands</div>
          <p className="mt-6">
            The Bollinger Bands indicator was established by John Bollinger.
            Moving averages are the most important component of Bollinger bands.
          </p>
          <p className="mt-4">
            On the top and downside, there are two standard deviations, with a
            traditional moving average in the middle.
          </p>
          <p className="mt-4">
            Overall, this trading indication is simple to use and offers a
            secure trade entry.
          </p>
          <p className="mt-4">
            Bollinger bands’ upper and lower lines serve as dynamic support and
            resistance levels.
          </p>
          <p className="mt-4">
            Any rejection at these levels suggests the possibility of admission.
            In addition, any breakout from these levels might lead to successful
            trades.
          </p>
          <p className="mt-4">
            A candle that closes below or above the middle line, on the other
            hand, opens the door to testing the following level.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a4.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>
        <div className="row-span-1">
          <div className="text-2xl font-bold">Stochastic</div>
          <p className="mt-6">
            The stochastic indicator, which was created in the early 1950s, is a
            prominent momentum indicator. This indicator’s primary goal is to
            identify the overbought and oversold zones.
          </p>
          <p className="mt-4">
            Traders frequently need to include a profit-taking zone in their
            trading plan.
          </p>
          <p className="mt-4">
            As a result, they employ this mt5 indication to determine the point
            at which the price is predicted to reverse. The Stochastic indicator
            fluctuates between 0 and 100.
          </p>
          <p className="mt-4">
            If the price rises over the 70 level, it will very certainly revert.
            If the price falls below the 30 levels, though, a bullish reversal
            is possible.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a5.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>

        <div className="row-span-1">
          <div className="text-2xl font-bold">Fibonacci</div>
          <p className="mt-6">
            Fibonacci is a trading tool that gives the most accurate market
            direction because it is tied to all living things.
          </p>
          <p className="mt-4">
            The golden ratio of 1.618 is the most important aspect of the
            Fibonacci tool. Traders in the forex market use this ratio to spot
            market reversals and profit-taking zones.
          </p>
          <p className="mt-4">
            If the price follows a trend, corrects towards the 61.8 percent
            Fibonacci retracement, and then reverses, the price will most likely
            advance to the current leg’s 161.8 percent Fibonacci extension
            level.
          </p>
          <p className="mt-4">
            Other Fibonacci levels based on market behavior and momentum include
            23.6 percent, 38.2 percent, 50.0 percent, 88.6 percent, 127.0
            percent, 261.8 percent, and so on.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a6.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>

        <div className="row-span-1">
          <div className="text-2xl font-bold">True Range Average</div>
          <p className="mt-6">
            The volatility of a currency pair is measured by its average true
            range. Volatility measurement is critical in the forex market since
            it is linked to direct market action.
          </p>
          <p className="mt-4">
            The increase of volatility in any financial market implies a market
            reversal, whereas the decrease of volatility indicates market
            continuity.
          </p>
          <p className="mt-4">
            The smaller the ATR, the lower the volatility, and the higher the
            ATR, the higher the volatility. As a result, if the volatility is
            minimal, you can increase the length of your take profit. Reversal
            trade setups, on the other hand, might be found amid lesser
            volatility.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a7.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>

        <div className="row-span-1">
          <div className="text-2xl font-bold">Parabola SAR</div>
          <p className="mt-6">
            The market trend of a currency pair is indicated by the parabolic
            SAR. The general trend is bullish if the price is above the
            parabolic SAR. If the price is below the SAR, however, the general
            trend is negative.
          </p>
          <p className="mt-4">
            Traders use this indication to identify the trend. Furthermore, a
            market rejection from the Parabolic SAR indicator provides a
            potential entry point.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a8.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>

        <div className="row-span-1">
          <div className="text-2xl font-bold">Pivot Point</div>
          <p className="mt-6">
            The equilibrium amount of supply and demand for a currency pair is
            shown by pivot points. When the price reaches the pivot point, it
            means that the supply and demand for the given pair are equal.
          </p>
          <p className="mt-4">
            When the price rises over the pivot point, it shows that there is
            strong demand for a currency pair. If the price falls below the
            pivot point, however, supply will be abundant.
          </p>
          <p className="mt-4">
            Before taking any direction in the financial market, pricing tends
            to move to the equilibrium point. As a result, the rejection of the
            pivot point gives a probable trading entry for this trading
            indicator.
          </p>
          <p className="mt-4">
            Forex indicators are crucial trading tools that all traders should
            be familiar with. The usefulness of a technical trading indicator,
            on the other hand, is determined by how you use it. To increase the
            likelihood of a market movement, traders frequently use many
            indicators, each with a particular parameter.
          </p>
        </div>
        <div className="row-span-1">
          <img
            src="https://leonllp.org/wp-content/uploads/2023/08/item-a9.png"
            alt="Moving Averages (MA)"
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default TechnicalAnalysis;
