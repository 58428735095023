import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  TextInput,
  Label,
  Select,
  Datepicker,
  Card,
  Table,
} from "flowbite-react";
import { FaAngleLeft } from "react-icons/fa";
import { HiOutlineCreditCard, HiUpload } from "react-icons/hi";
import countryList from "react-select-country-list";
import api from "../../utils/api";
import TransactionListCard from "../../components/TransactionListCard";
import ContractListCard from "../../components/ContractListCard";
import { toast } from "react-toastify";
import DepositModal from "../../components/DepositModal";
import WithdrawModal from "../../components/WithdrawModal";
import TransferModal from "../../components/TransferModal";
import CommentCard from "../../components/CommentCard";
import AccountCard from "../../components/AccountCard";

const dateToText = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const role = useSelector((s) => s.auth.user.role);
  const userId = useSelector((s) => s.auth.user.id);

  const countryOptions = useMemo(() => countryList().getData(), []);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    gender: 1,
    isVerified: 0,
    birthDate: "",
    phoneNumber: "",
    country: "",
    city: "",
    address: "",
    postalCode: "",
    id: "",
    registrationDate: "",
    lastLoginDate: "",
    profileId: "",
    adminId: "-1",
    purchasedQuantity: 0,
    IDFront: "",
    IDBack: "",
    POA: "",
    SOF: "",
    IDFrontStatus: 0,
    IDBackStatus: 0,
    POAStatus: 0,
    SOFStatus: 0,
  });

  const [formPasswordData, setFormPasswordData] = useState({
    password: "",
    password2: "",
  });

  const [withDrawData, setWithDrawData] = useState({
    amount: 0,
    purpose: "",
    walletAddress: "",
    network: "ETH",
    account: "",
    balance: 0,
  });
  const [depositData, setDepositData] = useState({
    amount: 0,
    purpose: "",
    method: 0,
    account: "",
    balance: 0,
  });

  const [isShowWithDraw, setIsShowWithDraw] = useState(false);
  const [isShowDeposit, setIsShowDeposit] = useState(false);

  const [employList, setEmployeeList] = useState([]);
  const [transactionTableData, setTransactionTableData] = useState([]);
  const [contractsTableData, setContractsTableData] = useState([]);

  const [accountTableData, setAccountTableData] = useState([]);
  const [isShowTransfer, setIsShowTransfer] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/users/id/${id}`);
        const data = response.data;
        setFormData({
          username: data.username || "",
          email: data.email || "",
          gender: data.gender,
          isVerified: data.isVerified || 0,
          birthDate: data.birthDate || "",
          phoneNumber: data.phoneNumber || "",
          country: data.country || "",
          city: data.city || "",
          address: data.address || "",
          postalCode: data.postalCode || "",
          id: data.id || "",
          registrationDate: data.registrationDate || "",
          lastLoginDate: data.lastLoginDate || "",
          profileId: data.profileId || "",
          adminId: data.adminId || "-1",
          purchasedQuantity: data.purchasedQuantity,
          IDFront: data.IDFront || "",
          IDBack: data.IDBack || "",
          POA: data.IDPOA || "",
          SOF: data.IDSOF || "",
          IDFrontStatus: data.IDFrontStatus,
          IDBackStatus: data.IDBackStatus,
          POAStatus: data.POAStatus,
          SOFStatus: data.SOFStatus,
        });
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchTransactionData() {
      try {
        const response = (await api.get(`/api/v1/transaction/user/${id}`)).data;
        setTransactionTableData(response);
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchContractsData() {
      try {
        const response = (await api.get(`/api/v1/contracts/user/${id}`)).data;
        setContractsTableData(response);
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchAccountData() {
      try {
        const response = (await api.get(`/api/v1/account/id/${id}`)).data;
        setAccountTableData(response);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
    fetchTransactionData();
    fetchContractsData();
    fetchAccountData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/api/v1/users/employees");
        const data = response.data;
        setEmployeeList(data);
      } catch (err) {
        console.log(err);
      }
    }

    if (role === 1) fetchData();
  }, [role]);

  const closeWithDrawModal = () => {
    setIsShowWithDraw(false);
  };

  const closeDepositModal = () => {
    setIsShowDeposit(false);
  };

  const handleChangeWithDraw = (e) => {
    const { name, value } = e.target;
    setWithDrawData({ ...withDrawData, [name]: value });
  };

  const handleChangeDeposit = (e) => {
    const { name, value } = e.target;
    setDepositData({ ...depositData, [name]: value });
  };

  const onSubmitWithdraw = async (e) => {
    try {
      await api.post(`/api/v1/transaction/admin/withdraw/${id}`, {
        ...withDrawData,
      });
      toast.success("Succeeded", { autoClose: 3000 });
      setWithDrawData({
        amount: 0,
        purpose: "",
        walletAddress: "",
        network: "ETH",
        account: "",
        balance: 0,
      });
      closeWithDrawModal();
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const onSubmitDeposit = async (e) => {
    try {
      await api.post(`/api/v1/transaction/admin/deposit/${id}`, {
        ...depositData,
      });
      toast.success("Succeeded", { autoClose: 3000 });

      setDepositData({
        amount: 0,
        purpose: "",
        method: 0,
        account: "",
        balance: 0,
      });
      closeDepositModal();
      window.open("https://nowpayments.io/pos-terminal/wwmvip/", "_blank");
    } catch (err) {
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const onSubmitTransfer = async (transferData) => {
    const response = await api.patch(
      `/api/v1/account/transfer-by-admin/${id}`,
      {
        from: transferData.from,
        to: transferData.to,
        amount: transferData.amount,
      }
    );
    setAccountTableData(response.data);
    toast.success("Succeeded", { autoClose: 3000 });

    closeTransferModal();
    return response;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      birthDate: dateToText(date),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.patch("/api/v1/profile/admin", { ...formData });
      toast.success("Succeeded!", {
        autoClose: 3000,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDocumentSubmit = async (e) => {
    try {
      await api.patch("/api/v1/profile/docstatus", {
        ...formData,
      });
      toast.success("Succeeded!", {
        autoClose: 3000,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setFormPasswordData({
      ...formPasswordData,
      [name]: value,
    });
  };

  const handleChangeAccountName = (e, id) => {
    const newAccountData = accountTableData.map((item) =>
      item.id === id ? { ...item, name: e.target.value } : item
    );
    setAccountTableData(newAccountData);
  };

  const onSubmitAccountName = async (e, accountId) => {
    if (e.key === "Enter") {
      try {
        await api.patch(`/api/v1/account/name/${accountId}`, {
          name: e.target.value,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onDeleteAccount = async (id) => {
    try {
      await api.delete(`/api/v1/account/${id}`);
      const filteredData = accountTableData.filter(
        (item) => Number(item.id) !== Number(id)
      );
      setAccountTableData(filteredData);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const closeTransferModal = () => {
    setIsShowTransfer(false);
  };

  const onApproveAccount = async (id) => {
    try {
      await api.patch(`/api/v1/account/active/${id}`);
      const filteredData = accountTableData.map((item) =>
        Number(item.id) === Number(id)
          ? { ...item, status: 1, detail: "" }
          : item
      );
      setAccountTableData(filteredData);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const handleSumbitChangePassword = async (e) => {
    e.preventDefault();
    try {
      if (formPasswordData.password !== formPasswordData.password2) {
        return toast.warn("Confirm password is not match.", {
          autoClose: 3000,
        });
      }
      await api.patch(`/api/v1/users/change-password/${id}`, {
        password: formPasswordData.password,
      });
      toast.success("Password change succeeded!", {
        autoClose: 3000,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const confirmTransactionDelete = async (selectedDel) => {
    try {
      const response = (
        await api.delete(`/api/v1/transaction/${id}/${selectedDel}`)
      ).data;
      setTransactionTableData(response);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const onChangeTransactionStatus = async (id, selected) => {
    try {
      const response = await api.patch(`/api/v1/transaction/status/${id}`, {
        status: selected,
        prev: transactionTableData.find((item) => item.id === id)?.status,
        amount: transactionTableData.find((item) => item.id === id)?.amount,
        userId: transactionTableData.find((item) => item.id === id)?.userId,
        accountId: transactionTableData.find((item) => item.id === id)
          ?.accountId,
        type: transactionTableData.find((item) => item.id === id)?.type,
        isUser: true,
      });
      setTransactionTableData(response.data);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const confirmContractDelete = async (selectedDel) => {
    try {
      const response = (
        await api.delete(`/api/v1/contracts/${id}/${selectedDel}`)
      ).data;
      setContractsTableData(response);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const onChangeContractStatus = async (id, selected) => {
    try {
      await api.patch(`/api/v1/contracts/status/${id}`, {
        status: selected,
      });
      const data = contractsTableData.map((item) =>
        item.id === id ? { ...item, status: selected } : item
      );
      setContractsTableData(data);
    } catch (err) {
      console.log(err);
      toast.error("Server error", { autoClose: 3000 });
    }
  };

  const documentList = [
    {
      type: "ID Front",
      name: "IDFront",
      url: formData.IDFront,
      status: formData.IDFrontStatus,
    },
    {
      type: "ID Back",
      name: "IDBack",
      url: formData.IDBack,
      status: formData.IDBackStatus,
    },
    {
      type: "Proof of Address",
      name: "POA",
      url: formData.POA,
      status: formData.POAStatus,
    },
    {
      type: "Proof of funds",
      name: "SOF",
      url: formData.SOF,
      status: formData.SOFStatus,
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mt-2 mb-6">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
          <FaAngleLeft
            className="cursor-pointer mr-2"
            onClick={() => navigate(-1)}
          />
          User Detail
        </h1>
        <div className="flex space-x-4">
          <Button
            color="blue"
            className="mr-2 hidden md:block"
            onClick={() => setIsShowWithDraw(true)}
          >
            <div className="flex items-center">
              <HiUpload className="inline-block" />
              &nbsp;Withdraw
            </div>
          </Button>
          <Button
            color="blue"
            className="hidden md:block"
            onClick={() => setIsShowDeposit(true)}
          >
            <div className="flex items-center">
              <HiOutlineCreditCard className="inline-block" />
              &nbsp;Deposit
            </div>
          </Button>
        </div>
      </div>
      <Card>
        <h3 className="text-xl font-semibold dark:text-white">
          General information
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="username" value="Name" />
              </div>
              <TextInput
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput id="email" defaultValue={formData.email} readOnly />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="gender" value="Gender" />
              </div>
              <Select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value={1}>Male</option>
                <option value={0}>Female</option>
              </Select>
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="role" value="Role" />
              </div>
              <Select id="role" value="user" disabled>
                <option value="user">User</option>
              </Select>
            </div>

            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="birthDate" value="BirthDate" />
              </div>
              <Datepicker
                id="birthDate"
                name="birthDate"
                value={formData.birthDate}
                selected={new Date(formData.birthDate)}
                onSelectedDateChanged={(date) => handleDateChange(date)}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="phoneNumber" value="Phone Number" />
              </div>
              <TextInput
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="country" value="Country" />
              </div>
              <Select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                {countryOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="city" value="City" />
              </div>
              <TextInput
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="address" value="Address" />
              </div>
              <TextInput
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="postalCode" value="Postal Code" />
              </div>
              <TextInput
                id="postalCode"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="registrationDate" value="Registration Date" />
              </div>
              <TextInput
                id="registrationDate"
                defaultValue={formData.registrationDate}
                readOnly
                disabled
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="lastLoginDate" value="Last Login" />
              </div>
              <TextInput
                id="lastLoginDate"
                defaultValue={formData.lastLoginDate}
                readOnly
                disabled
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="purchasedQuantity" value="Purchased Quantity" />
              </div>
              <TextInput
                id="purchasedQuantity"
                value={formData.purchasedQuantity}
                onChange={() => {}}
                readOnly
                disabled
              />
            </div>
            {role === 1 ? (
              <div className="col-span-8 md:col-span-2">
                <div className="mb-2 block">
                  <Label htmlFor="adminId" value="Admin" />
                </div>
                <Select
                  id="adminId"
                  name="adminId"
                  value={formData.adminId}
                  onChange={handleChange}
                >
                  <option value="-1">Select Admin</option>
                  {employList.map(({ id, username }) => (
                    <option value={id} key={id}>
                      {username}
                    </option>
                  ))}
                </Select>
              </div>
            ) : null}
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="isVerified" value="Verification Status" />
              </div>
              <Select
                id="isVerified"
                name="isVerified"
                value={formData.isVerified}
                onChange={handleChange}
              >
                <option value={0}>Pending</option>
                <option value={1}>Approved</option>
              </Select>
            </div>
            <div className="col-span-8 sm:col-ful2">
              <Button color="blue" type="submit">
                Save all
              </Button>
            </div>
          </div>
        </form>
      </Card>
      <div className="mt-4">
        <AccountCard
          role={1}
          userId={id}
          accountTableData={accountTableData}
          handleChangeName={handleChangeAccountName}
          submitName={onSubmitAccountName}
          deleteAccount={onDeleteAccount}
          approveAccount={onApproveAccount}
          showTransferModal={() => setIsShowTransfer(true)}
        />
      </div>
      <div className="mt-4">
        <CommentCard userId={id} adminId={userId} />
      </div>
      <Card className="mt-4">
        <h3 className="text-xl font-semibold dark:text-white">Security</h3>
        <form
          onSubmit={handleSumbitChangePassword}
          className="grid grid-cols-8 gap-6"
        >
          <div className="col-span-8 md:col-span-4">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              required
              value={formPasswordData.password}
              onChange={handleChangePassword}
            />
          </div>
          <div className="col-span-8 md:col-span-4">
            <label
              htmlFor="password2"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Confirm password
            </label>
            <input
              type="password"
              name="password2"
              id="password2"
              placeholder="••••••••"
              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              required
              value={formPasswordData.password2}
              onChange={handleChangePassword}
            />
          </div>

          <div className="col-span-8 sm:col-span-8">
            <Button color="blue" type="submit">
              Change Password
            </Button>
          </div>
        </form>
      </Card>
      <Card className="mt-4 overflow-x-auto">
        <h3 className="text-xl font-semibold dark:text-white">
          Document Verification
        </h3>
        <Table>
          <Table.Head>
            <Table.HeadCell>Type</Table.HeadCell>
            <Table.HeadCell>URL</Table.HeadCell>
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {documentList.map((row, index) => (
              <Table.Row
                key={index}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.type}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {row.url ? (
                    <a
                      href={`https://${process.env.REACT_APP_SERVER_DOMAIN_NAME}/api/${row.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      Download
                    </a>
                  ) : (
                    "None"
                  )}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  <Select
                    id={`${row.name}Status`}
                    name={`${row.name}Status`}
                    value={formData[`${row.name}Status`]}
                    onChange={handleChange}
                  >
                    <option value={0}>Pending</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                  </Select>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="col-span-8 sm:col-ful2">
          <Button color="blue" onClick={() => handleDocumentSubmit()}>
            Save all
          </Button>
        </div>
      </Card>
      <div className="mt-4">
        <TransactionListCard
          role={role}
          tableData={transactionTableData}
          onChangeStatus={onChangeTransactionStatus}
          confirmDelete={confirmTransactionDelete}
        />
      </div>
      <div className="mt-4">
        <ContractListCard
          role={role}
          tableData={contractsTableData}
          onChangeStatus={onChangeContractStatus}
          confirmDelete={confirmContractDelete}
        />
      </div>
      <DepositModal
        accountList={accountTableData}
        isShowDeposit={isShowDeposit}
        closeDepositModal={closeDepositModal}
        depositData={depositData}
        handleChangeDeposit={handleChangeDeposit}
        onSubmitDeposit={onSubmitDeposit}
      />
      <WithdrawModal
        accountList={accountTableData}
        isShowWithDraw={isShowWithDraw}
        closeWithDrawModal={closeWithDrawModal}
        withDrawData={withDrawData}
        handleChangeWithDraw={handleChangeWithDraw}
        onSubmitWithdraw={onSubmitWithdraw}
      />
      <TransferModal
        isShowTransfer={isShowTransfer}
        closeTransferModal={closeTransferModal}
        onSubmitTransfer={onSubmitTransfer}
        accountList={accountTableData}
      />
    </div>
  );
};

export default UserDetail;
