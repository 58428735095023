import { TickerTape } from 'react-ts-tradingview-widgets';

const CryptoCurrency = () => {
    return (
        <div className='text-base md:text-lg break-all'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">Cryptocurrency Market</div>
                    <p className="mt-8">
                    Price movements on cryptocurrencies like Bitcoin or Ethereum are primarily driven by news and prevailing sentiment, i.e. the fear and greed of retail speculators. These, sometimes, dramatic shifts can lead to massive intraday price swings, making cryptocurrency CFDs an exciting product for aggressive and experienced day traders.
                    </p>
                    <p className="mt-4">
                    The cryptocurrency CFD product allows traders to go long or short without actually holding the cryptocurrency. This means traders can get exposure to the price of the cryptocurrency without worrying about the security risks associated with storing it and the counterparty risk from the exchange. This is similar to trading energy futures such as oil, rather than owning physical oil to speculate on its price.
                    </p>
                </div>
            </div>

            <div className="mb-4">
                <div className="p-4">
                <div className="market-data-widget-container">
                    <TickerTape
                       showSymbolLogo={true}
                       colorTheme="dark"
                       isTransparent={false}
                       displayMode="adaptive"
                       locale="en"
                        symbols={[
                        {
                            proName: "BITSTAMP:BTCUSD",
                            title: "Bitcoin"
                        },
                        {
                            proName: "BITSTAMP:ETHUSD",
                            title: "Ethereum"
                        },
                        {
                            description: "",
                            proName: "BINANCEUS:USDTUSD"
                        },
                        {
                            description: "",
                            proName: "BINANCEUS:SOLUSD"
                        },
                        {
                            description: "",
                            proName: "BINANCEUS:VETUSD"
                        },
                        {
                            description: "",
                            proName: "BINANCEUS:MATICUSD"
                        },
                        {
                            description: "",
                            proName: "BINANCEUS:DOGEUSD"
                        }
                        ]}
                    />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-4"></div>
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">How does Crypto Trading Work?</div>
                    <p className="mt-8">
                    Bitcoin is the most popular digital cryptocurrency that derives its value from supply and demand factors unique to this asset class. Bitcoin is available in a finite supply and, therefore, increases in price as demand increases.
                    </p>
                    <p className="mt-4">
                    Demand stems from speculative sources and more practical sources, for example, internet purchases paid for in Bitcoin.
                    </p>
                    <p className="mt-4">
                    Bitcoin also has a tendency to react to market sentiment of more traditional markets such as equities and foreign exchange, increasing during periods of negative sentiment.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default CryptoCurrency;