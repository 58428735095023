const WhoWeAre = () => {
    return (
        <div className="text-base md:text-lg break-all">
            <div className="gap-4 mb-4">
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">About WWM VIP</div>
                    <p className="mt-8">
                    WWM VIP is a trading name of West Wealth Management Ltd, which is incorporated in the United Kingdom, and registered by the Financial Services Authority, with an address at Assheton Lane, Twycross, Atherstone, Warwickshire, England, CV9 3QQ. WWM VIP is 100% owned by West Wealth Management Financial Services Ltd, a company incorporated in the UK.
                    </p>
                    <p className="mt-4">
                    Over-the-counter derivatives are complex instruments and involve the use of leverage, which can lead to substantial returns but also requires careful consideration. It's important to ensure you understand how over-the-counter derivatives work and thoughtfully evaluate your financial situation before engaging in such investments. 
                    These investments may not be suitable for everyone. 
                    When acquiring our derivative products, you have no entitlement, right, or obligation to the underlying financial asset. WWM VIP is not a financial adviser, and all services are provided on an execution-only basis. Information is of a general nature only and does not consider your financial objectives, needs, or personal circumstances. Important legal documents related to our products and services are available on our website. You should read and understand these documents before applying for any WWM VIP products or services and obtain independent professional advice as necessary.
                    </p>
                    <p className="mt-4">
                    WWM VIP Limited is a member of The Financial Commission, an international organization engaged in the resolution of disputes within the financial services industry in the Forex market.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default WhoWeAre;