import React, { useEffect, useState } from "react";
import { Card, Button, Textarea } from "flowbite-react";
import { HiTrash } from "react-icons/hi";
import api from "../utils/api";
import { toast } from "react-toastify";

const CommentCard = ({ userId, adminId }) => {
  const [initialComment, setInitialComment] = useState("");
  const [formData, setFormData] = useState({
    comment: "",
    list: [],
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/comments/user/${userId}`);
        const data = response.data;
        setFormData({
          comment: data.comment || "",
          list: data.list,
        });
        setInitialComment(data.comment || "");
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [userId]);

  const handleChange = (e) => {
    setFormData({ ...formData, comment: e.target.value });
  };

  const handleDeleteComment = async (id) => {
    try {
      await api.delete(`/api/v1/comments/user/${id}`);
      const response = await api.get(`/api/v1/comments/user/${userId}`);
      const data = response.data;
      setFormData({
        comment: data.comment || "",
        list: data.list,
      });
      setInitialComment(data.comment || "");
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    try {
      if (Object.keys(initialComment).length === 0) {
        await api.post(`/api/v1/comments/${userId}`, {
          comment: formData.comment,
        });
      } else {
        await api.patch(`/api/v1/comments/user/${initialComment.id}`, {
          comment: formData.comment,
        });
      }
      const response = await api.get(`/api/v1/comments/user/${userId}`);
      const data = response.data;
      setFormData({
        comment: data.comment || {},
        list: data.list,
      });
      setInitialComment(data.comment || "");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Card className="overflow-x-auto">
        <h3 className="text-xl font-semibold dark:text-white">Comments</h3>
        <div>
          <Textarea
            id="comment"
            name="comment"
            value={formData.comment.comment}
            onChange={handleChange}
            className="p-2.5"
            rows={4}
          />
        </div>
        <div>
          <Button color="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </div>

        <div className="mt-4">
          {formData.list.length > 0 ? (
            <ul className="space-y-2">
              {formData.list.map((comment) => (
                <li key={comment.id} className="p-2 border rounded">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-lg font-semibold">
                        {comment.adminName}
                      </p>
                      <p className="text-sm text-gray-500">
                        {comment.createDateTime}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: comment.comment.replace(/\n/g, "<br />"),
                        }}
                      />

                      {/* <p>{comment.comment}</p> */}
                    </div>
                    {comment.adminId === adminId ? (
                      <div className="flex space-x-2">
                        <button>
                          <HiTrash
                            className="h-5 w-5 text-red-500 cursor-pointer"
                            onClick={() => handleDeleteComment(comment.id)}
                          />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No comments available</p>
          )}
        </div>
      </Card>
    </div>
  );
};

export default CommentCard;
