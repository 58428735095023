import { useEffect, useRef } from "react";

const Stocks = () => {
    const widgetContainerRef = useRef(null);
    const scriptAppendedRef = useRef(false);
  
    useEffect(() => {
      if (scriptAppendedRef.current) return;
  
      const script = document.createElement('script');
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        width: "100%",
        height: "300",
        symbolsGroups: [
          {
            name: "Stocks",
            originalName: "Stocks",
            symbols: [
              { name: "NASDAQ:AAPL", displayName: "Apple" },
              { name: "NASDAQ:GOOGL", displayName: "Alphabet" },
              { name: "NASDAQ:AMZN", displayName: "Amazon" },
              { name: "NASDAQ:MSFT", displayName: "Microsoft" },
              { name: "NYSE:TSLA", displayName: "Tesla" },
              { name: "NASDAQ:FB", displayName: "Meta Platforms" }
            ]
          }
        ],
        showSymbolLogo: true,
        colorTheme: "dark",
        isTransparent: false,
        displayMode: "adaptive",
        locale: "en"
      });
  
      widgetContainerRef.current.appendChild(script);
      scriptAppendedRef.current = true;
    }, []);

    return (
        <div className="text-base md:text-lg break-all">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">What are Stocks?</div>
                    <p className="mt-8">
                    Stocks, also known as equities, represent ownership in a company and constitute a claim on part of the company’s assets and earnings. Since the establishment of stock exchanges in the 17th century, stocks have been one of the most popular investment vehicles for individuals and institutions alike. Besides bonds and mutual funds, buying and trading stocks is a globally popular way for investors to build wealth, diversify portfolios, and achieve long-term financial goals.
                    </p>
                    <p className="mt-4">
                    Stocks are traded on stock exchanges, such as the New York Stock Exchange (NYSE) and the Nasdaq, where buyers and sellers come together to exchange shares. The price of a stock is determined by supply and demand dynamics in the market, which are influenced by a variety of factors, including company performance, economic indicators, and investor sentiment. Stocks are typically categorized into two main types: common stocks and preferred stocks. Common stocks usually provide voting rights and potential for dividends, while preferred stocks typically offer fixed dividends and have priority over common stocks in the event of liquidation.
                    </p>
                </div>
            
            </div>

            <div className="mb-4">
                <div className="p-4">
                    <div className="tradingview-widget-container" ref={widgetContainerRef}>
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-4"></div>
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">How to Trade Stocks</div>
                    <p className="mt-8">
                    Trade Stocks with a worldwide network of Stock Exchanges, Brokers, Institutional Investors, and Retail Traders. Our platform brings a wealth of experience to our clients.
                    </p>
                    <p className="mt-4">
                    Trade Stocks with a worldwide network of Stock Exchanges, Brokers, Institutional Investors, and Retail Traders. Our platform brings a wealth of experience to our clients. Stock traders will have access to real-time market data, advanced charting tools, account management, tight spreads, and comprehensive analysis.
                    </p>
                    <p className="mt-4">
                    Using Our Trading Platform technology, traders and partners will have access to a wide range of stock trading products in addition to many other products in Forex, commodities, indices, and cryptocurrencies CFD’s.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default Stocks;