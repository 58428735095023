import React from 'react';

const AdvantageBlock = () => {
  return (
    <div className="section advantages-section text-black p-4 md:p-0 mt-0 md:mt-24">
      <div className="container">
        <div className="advantages-block">
          <div className="advantages">
            <div className="advantage">
              <div className="advantage__bg wow" style={{ animationName: 'slideInLeftCircle' }}></div>
              <img
                src="https://leonllp.org/wp-content/themes/investpole/img/advantages-icons/1.svg"
                alt="Alt"
                className="advantage__icon wow"
                style={{ animationName: 'slideInLeftCircle', animationDelay: '0.25s' }}
              />
              <div className="advantage-info">
                <div className="advantage__title">Access low fees</div>
                <div className="advantage__text">
                    Buy contracts with high Return On Investment and fees as low as 0%
                </div>
              </div>
            </div>
            <div className="advantage">
              <div className="advantage__bg wow" style={{ animationName: 'slideInLeftCircle' }}></div>
              <img
                src="https://leonllp.org/wp-content/themes/investpole/img/advantages-icons/2.svg"
                alt="Alt"
                className="advantage__icon wow"
                style={{ animationName: 'slideInLeftCircle', animationDelay: '0.25s' }}
              />
              <div className="advantage-info">
                <div className="advantage__title">Up to 1200 Contracts to buy</div>
                <div className="advantage__text">
                FX, Crypto, Commodities, ETFs and more
                </div>
              </div>
            </div>
            <div className="advantage">
              <div className="advantage__bg wow" style={{ animationName: 'slideInLeftCircle' }}></div>
              <img
                src="https://leonllp.org/wp-content/themes/investpole/img/advantages-icons/3.svg"
                alt="Alt"
                className="advantage__icon wow"
                style={{ animationName: 'slideInLeftCircle', animationDelay: '0.25s' }}
              />
              <div className="advantage-info">
                <div className="advantage__title">Fast & reliable</div>
                <div className="advantage__text">
                Fast execution, 99.99% fill rate,<br />
                and no dealing desk intervention
                </div>
              </div>
            </div>
            <div className="advantage">
              <div className="advantage__bg wow" style={{ animationName: 'slideInLeftCircle' }}></div>
              <img
                src="https://leonllp.org/wp-content/themes/investpole/img/advantages-icons/4.svg"
                alt="Alt"
                className="advantage__icon wow"
                style={{ animationName: 'slideInLeftCircle', animationDelay: '0.25s' }}
              />
              <div className="advantage-info">
                <div className="advantage__title">Regulated broker</div>
                <div className="advantage__text">
                FCA, ASIC, DFSA, SCB, CMA, CySEC &<br />BaFin
                </div>
              </div>
            </div>
          </div>
          <div className="advantages__image" style={{ animationName: 'zoomImage' }}>
            <img src="https://leonllp.org/wp-content/themes/investpole/img/smartphone.png" alt="Alt" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvantageBlock;