import React from "react";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
      <p className="text-xl text-gray-600 mb-8">
        Oops! The page you are looking for does not exist.
      </p>
      <Button gradientMonochrome="info" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </div>
  );
};

export default NotFoundPage;
