import { Card } from "flowbite-react";
import { useState, useEffect } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import FileUploadCard from "../../components/FileUploadCard";
import api from "../../utils/api";

const Documents = () => {
  const [statuses, setStatuses] = useState({
    IDFront: "idle",
    IDBack: "idle",
    POA: "idle",
    SOF: "idle",
  });

  const [documentURLs, setDocumentURLs] = useState({
    IDFront: null,
    IDBack: null,
    POA: null,
    SOF: null,
  });

  const [documentStatus, setDocumentStatus] = useState({
    IDFrontStatus: null,
    IDBackStatus: null,
    POAStatus: null,
    SOFStatus: null,
  });

  const handleDrop = (event, id) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFileUpload(files[0], id);
    }
  };

  const handleChange = (event, id) => {
    const files = event.target.files;
    if (files.length) {
      handleFileUpload(files[0], id);
    }
  };

  const handleFileUpload = async (file, id) => {
    setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: "uploading" }));

    const data = new FormData();
    data.append("type", id);
    data.append("documentFile", file);

    try {
      const response = await api.post("/api/v1/profile/document", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setDocumentURLs({ ...documentURLs, [id]: response.data.path });

      setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: "done" }));
    } catch (error) {
      console.error("Error uploading file:", error);
      setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: "error" }));
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.patch("/api/v1/profile/document", { type: id });
      setDocumentURLs({ ...documentURLs, [id]: null });
      setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: "idle" }));
    } catch (error) {
      console.log("Error deleting file:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/api/v1/profile/document");
        setDocumentURLs({ ...response.data });
        setDocumentStatus({ ...response.data });
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="p-0">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Documents
        </h1>
      </div>
      <Card>
        <h3 className="text-xl font-semibold dark:text-white">Documents</h3>
        <div className="px-0 break-all xl:break-normal grid grid-flow-cols gap-4">
          <div>
            In accordance with the VERIFICATION procedure, client verification
            is a prerequisite for working in the financial markets. The passage
            of VERIFICATION consists in the provision of reasonably complete
            personal information by the client, in order to establish the
            identity of the counterparty and verify the legality of
            transactions.
          </div>

          <div>
            We use secure socket layers (SSL), that prevents hackers from
            accessing information and protects user data.
          </div>
        </div>
      </Card>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 gap-4 mt-4 xl:mt-8">
        {[
          { label: "ID Front", id: "IDFront" },
          { label: "ID Back", id: "IDBack" },
          { label: "Proof of Address", id: "POA" },
          { label: "Proof of funds", id: "SOF" },
        ].map(({ label, id }) => (
          <FileUploadCard
            key={id}
            id={id}
            label={label}
            uploadStatus={statuses[id]}
            handleDrop={handleDrop}
            handleChange={handleChange}
            handleDelete={handleDelete}
            path={documentURLs[id]}
            status={documentStatus[`${id}Status`]}
          />
        ))}
      </div>

      <div className="mt-8">
        <Card className="col-span-1 mx-0 xl:mx-4">
          <h3 className="text-lg xl:text-xl font-semibold dark:text-white">
            Documents requirements
          </h3>
          <div className="grid grid-flow-cols px-0 xl:px-4">
            <div className="flex items-center">
              <IoMdCheckmarkCircleOutline className="text-green-400" />
              &nbsp; All four corners of the document must be visible
            </div>
            <div className="flex items-center">
              <IoMdCheckmarkCircleOutline className="text-green-400" />
              &nbsp; All data on the document must be visible and readable
            </div>
            <div className="flex items-center">
              <IoMdCheckmarkCircleOutline className="text-green-400" />
              &nbsp; Residence confirmation document must be no older than 3
              month
            </div>
            <div className="flex items-center">
              <IoMdCheckmarkCircleOutline className="text-green-400" />
              &nbsp; Photo or scan are allowed
            </div>
          </div>
        </Card>
      </div>
      <div className="col-span-1"></div>
    </div>
  );
};

export default Documents;
