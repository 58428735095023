import { Link } from "react-router-dom";

const PricePackage = ({ packagename, amount, fees, service, icon }) => {
  return (
    <div className="bg-secondary dark:bg-secondary-dark p-8 rounded-md m-4 md:m-0">
      <div className="w-full flex-grow flex flex-col items-center">
        <i className="text-5xl md:text-6xl font-bold uppercase mb-4">{icon}</i>
        <h2 className="text-center text-xl md:text-2xl font-bold uppercase mb-4 text-black text-text-default dark:text-text-dark">
          {packagename}
        </h2>
        <h3 className="text-center text-4xl md:text-5xl text-color font-bold mb-5">
          ${amount}
        </h3>
        <ul className="text-center text-sm md:text-base mb-8">
          <li className="leading-tight">
            <i className="text-xl md:text-2xl">Fees: {fees}</i>
          </li>
          <li className="leading-tight mt-4">
            <i className="text-lg"></i>
            {service}
          </li>
        </ul>
      </div>
      <div className="w-full">
        <Link to="signup">
          <button className="bg-primary-button font-bold hover:bg-green-700  text-white rounded-md px-10 py-2 transition-colors w-full rounded-full">
            Buy Now
          </button>
        </Link>
        <Link to="/packages">
          <button className="mt-4 font-bold bg-gray-600 hover:bg-gray-700 text-text-default dark:text-text-dark rounded-md px-10 py-2 transition-colors w-full rounded-full">
            Learn More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PricePackage;
