import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="flex flex-col items-center justify-center min-h-screen p-2">
      <div className="container mx-auto">
        <div className="w-full">
          <div
            className="flex flex-col items-center justify-center text-center animate__animated animate__fadeInUp gap-8 lg:gap-10 xl:gap-16"
            style={{ animationDelay: "1s" }}
          >
            <h1
              className="text-5xl lg:text-5xl xl:text-7xl font-bold w-full text-center animate-[fadeInUp_1s_ease-in-out]"
              style={{ animationDelay: "1s" }}
            >
              <span className="text-color">VIP Contract Manager</span>
            </h1>
            <h3
              className="text-3xl xl:text-4xl font-bold w-full text-center animate-[fadeInUp_1s_ease-in-out]"
              style={{ animationDelay: "1s" }}
            >
              Highest returns, safest platform
            </h3>
            <div
              className="lg:w-8/12 text-xl xl:text-2xl w-full animate-[fadeInUp_1s_ease-in-out]"
              style={{ animationDelay: "1s" }}
            >
              <p>
                The only Global ETF contracts provider in the world, intuitive
                trading tools managed by expert financial brokers and market
                insights to help you execute your trading strategies.
              </p>
            </div>
            <div
              className="flex font-bold text-base lg:text-lg xl:text-xl gap-3 lg:gap-4 xl:gap-6 animate-[fadeInUp_1s_ease-in-out]"
              style={{ animationDelay: "1s" }}
            >
              <Link
                to="/signup"
                className="bg-primary-button hover:bg-green-700 text-white py-3 px-4 lg:py-3 lg:px-6 xl:py-4 xl:px-8 rounded-full"
              >
                Sign Up
              </Link>
              <Link
                to="/signin"
                className="bg-gray-600 hover:bg-gray-700 text-white py-3 px-4 xl:py-4 lg:py-3 lg:px-6 xl:px-8 rounded-full"
              >
                Sign In
              </Link>
              <a
                href="https://find-and-update.company-information.service.gov.uk/company/09986567"
                target="_blank"
                rel="noreferrer"
                className="bg-gray-600 hover:bg-gray-700 text-white py-3 px-4 xl:py-4 lg:py-3 lg:px-6 xl:px-8 rounded-full"
              >
                Check License
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
