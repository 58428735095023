import { useEffect, useState } from "react";
import { Modal, Select, Button } from "flowbite-react";
import api from "../utils/api";

const ChangeAdminModal = ({ showModal, cancelModal, submit }) => {
  const [admin, setAdmin] = useState(-1);
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/api/v1/users/employees");
        const data = response.data;
        setEmployeeList(data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  return (
    <Modal show={showModal} size="md" onClose={cancelModal}>
      <Modal.Header>Select </Modal.Header>
      <Modal.Body className="px-6 pt-4">
        <Select
          id="adminId"
          name="adminId"
          value={admin}
          onChange={(e) => setAdmin(e.target.value)}
        >
          <option value="-1">Select Admin</option>
          {employeeList.map(({ id, username }) => (
            <option value={id} key={id}>
              {username}
            </option>
          ))}
        </Select>
        <Button
          color="blue"
          className="w-full mt-4"
          onClick={() => submit(admin)}
        >
          Submit
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeAdminModal;
