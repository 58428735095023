import {
  Modal,
  TextInput,
  Select,
  Textarea,
  Label,
  Button,
} from "flowbite-react";
import { convertType } from "../utils/config";

const DepositModal = ({
  isShowDeposit,
  closeDepositModal,
  depositData,
  handleChangeDeposit,
  onSubmitDeposit,
  accountList,
}) => {
  return (
    <Modal
      id="deposit-modal"
      show={isShowDeposit}
      size="md"
      onClose={closeDepositModal}
    >
      <Modal.Header className="p-4">Deposit</Modal.Header>
      <Modal.Body className="px-6 pt-2">
        <div className="grid grid-flow-row gap-4">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="account" value="Account" />
            </div>
            <Select
              id="account"
              name="account"
              value={depositData.account}
              onChange={handleChangeDeposit}
              required
            >
              <option value="">Select an account</option>
              {accountList
                .filter((item) => item.status === 1)
                .map((item) => (
                  <option key={item.id} value={item.id}>
                    {convertType(item.type)}
                  </option>
                ))}
            </Select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="balance" value="Balance" />
            </div>
            <TextInput
              id="balance"
              name="balance"
              value={depositData.balance.toFixed(2)}
              readOnly
              disabled
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="deposit-amount" value="Amount" />
            </div>
            <TextInput
              id="deposit-amount"
              name="amount"
              type="number"
              value={depositData.amount}
              onChange={handleChangeDeposit}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="method" value="Method" />
            </div>
            <Select
              id="method"
              name="method"
              value={depositData.method}
              onChange={handleChangeDeposit}
            >
              <option value={0}>Crypto</option>
            </Select>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="purpose" value="Purpose" />
            </div>
            <Textarea
              id="purpose"
              name="purpose"
              value={depositData.purpose}
              onChange={handleChangeDeposit}
              className="p-2.5"
              rows={4}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="blue" onClick={onSubmitDeposit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DepositModal;
