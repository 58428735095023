import React from "react";
import {
  BiCloudUpload,
  BiLoader,
  BiCheckCircle,
  BiError,
} from "react-icons/bi";
import { FiEye, FiTrash } from "react-icons/fi";
import { Card, Label, FileInput } from "flowbite-react";
import StatusBadgeComponent from "../components/StatusBadgeComponent";

function isImageUrl(url) {
  // Define a regular expression for common image file extensions
  const imagePattern = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

  // Check if the string is a valid URL
  try {
    new URL(url);
  } catch (_) {
    return false; // Not a valid URL
  }

  // Check if the URL ends with a common image file extension
  return imagePattern.test(url);
}

const FileUploadCard = ({
  label,
  uploadStatus,
  handleDrop,
  handleChange,
  id,
  path,
  status,
  handleDelete,
}) => {
  // const imageURL = `http://${process.env.REACT_APP_SERVER_URL}:${process.env.REACT_APP_SERVER_PORT}\/${path}`;
  const imageURL = `https://${process.env.REACT_APP_SERVER_DOMAIN_NAME}/api\/${path}`;
  return (
    <Card className="col-span-1 mx-0 xl:mx-4">
      <h3 className="text-lg xl:text-xl font-semibold dark:text-white flex items-center">
        {label}
        {path !== undefined && path !== null ? (
          <StatusBadgeComponent status={status} />
        ) : null}
      </h3>
      <div
        className="flex w-full items-center justify-center"
        onDrop={(event) => handleDrop(event, id)}
        onDragOver={(event) => event.preventDefault()}
      >
        {path === undefined || path === null ? (
          <Label
            htmlFor={id}
            className={`flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600`}
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              {uploadStatus === "idle" && (
                <>
                  <BiCloudUpload className="mb-4 h-10 w-10 text-gray-500 dark:text-gray-400" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                </>
              )}
              {uploadStatus === "uploading" && (
                <>
                  <BiLoader className="mb-4 h-10 w-10 text-gray-500 dark:text-gray-400 animate-spin" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    Uploading...
                  </p>
                </>
              )}
              {uploadStatus === "done" && (
                <>
                  <BiCheckCircle className="mb-4 h-10 w-10 text-green-500 dark:text-green-400" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    Upload complete!
                  </p>
                </>
              )}
              {uploadStatus === "error" && (
                <>
                  <BiError className="mb-4 h-10 w-10 text-red-500 dark:text-red-400" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    Upload failed. Try again.
                  </p>
                </>
              )}
            </div>
            <FileInput
              id={id}
              name={id}
              className="hidden"
              onChange={(event) => handleChange(event, id)}
            />
          </Label>
        ) : (
          <Label
            htmlFor={id}
            className={`relative flex h-64 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600`}
          >
            {isImageUrl(imageURL) ? (
              <img
                src={imageURL}
                alt="Card Content"
                className="absolute inset-0 h-full w-full object-cover rounded-lg"
              />
            ) : null}
            <img
              src={imageURL}
              alt="Card Content"
              className="absolute inset-0 h-full w-full object-cover rounded-lg"
            />
            <div className="relative flex flex-col items-center">
              <div className="flex space-x-4 mt-4 text-lg">
                <a
                  href={`https://${process.env.REACT_APP_SERVER_DOMAIN_NAME}/api/${path}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiEye className="text-gray-500 cursor-pointer  hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" />
                </a>
                <FiTrash
                  className="text-gray-500 cursor-pointer hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  onClick={() => handleDelete(id)}
                />
              </div>
            </div>
          </Label>
        )}
      </div>
    </Card>
  );
};

export default FileUploadCard;
