import PricePackage from "./PricePackage";
import { BsCash, BsCashCoin, BsCoin } from "react-icons/bs";

const PriceList = () => {
  return (
    <section>
      <div className="max-w-6xl mx-auto md:flex gap-6 md:gap-12 font-bold">
        <PricePackage
          packagename="Basic:"
          amount="499"
          fees="Standard"
          service="Dedicated Account Manager"
          icon={<BsCoin />}
        />
        <PricePackage
          packagename="Advanced:"
          amount="1 999"
          fees="Competitive"
          service="Junior Financial Expert"
          icon={<BsCash />}
        />
        <PricePackage
          packagename="Premium:"
          amount="4 999"
          fees="Low"
          service="Senior Financial Expert"
          icon={<BsCashCoin />}
        />
      </div>
    </section>
  );
};

export default PriceList;
