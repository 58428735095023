import { Button, Card, Label, TextInput, Textarea } from "flowbite-react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { FaAngleLeft } from "react-icons/fa";

const ActiveContractPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    offerTitle: "",
    lockPeriod: 0,
    price: 0,
    profitPercentage: 0,
    description: "",
    comment: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/contracts/id/${id}`);
        const data = response.data;

        setFormData({
          offerTitle: data.offerTitle || "",
          lockPeriod: data.lockPeriod || 0,
          price: data.price || 0,
          profitPercentage: data.profitPercentage || 0,
          description: data.description || "",
          comment: data.comment || "",
        });
      } catch (err) {
        console.log(err);
      }
    }

    if (id !== undefined) fetchData();
  }, [id]);

  return (
    <div className="p-0">
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          Contracts
        </h1>
      </div>
      <div className="grid grid-flow-cols gap-4">
        <Card>
          <h3 className="text-xl font-semibold dark:text-white">Contract</h3>
          <div className="px-0 break-all xl:break-normal xl:px-4 grid grid-flow-cols gap-4">
            <div className="grid grid-cols-8 gap-6 mb-4">
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="offerTitle" value="Offer" />
                </div>
                <TextInput
                  id="offerTitle"
                  name="offerTitle"
                  defaultValue={formData.offerTitle}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="lock-period" value="Lock Period" />
                </div>
                <TextInput
                  id="lock-period"
                  name="lockPeriod"
                  type="number"
                  value={formData.lockPeriod}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="price" value="Price" />
                </div>
                <TextInput
                  id="price"
                  name="price"
                  type="number"
                  value={formData.price}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="profit-percentage"
                    value="Profit Percentage"
                  />
                </div>
                <TextInput
                  id="profit-percentage"
                  name="profitPercentage"
                  type="number"
                  value={formData.profitPercentage}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div className="col-span-8">
                <div className="mb-2 block">
                  <Label htmlFor="description" value="Description" />
                </div>
                <Textarea
                  id="description"
                  name="description"
                  defaultValue={formData.description}
                  className="p-2.5"
                  rows={4}
                  readOnly
                />
              </div>
              <div className="col-span-8">
                <div className="mb-2 block">
                  <Label htmlFor="comment" value="Comment" />
                </div>
                <Textarea
                  id="comment"
                  name="comment"
                  defaultValue={formData.comment}
                  className="p-2.5"
                  rows={4}
                  readOnly
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ActiveContractPage;
