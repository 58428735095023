import { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Card, TextInput, Button } from "flowbite-react";
import { HiTrash } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import StatusComponent from "./StatusComponent";
import ConfirmModal from "./ConfirmModal";
import { convertType } from "../utils/config";

const AccountCard = ({
  role,
  userId,
  accountTableData,
  deleteAccount,
  cancelPendingOpenAccount,
  cancelPendingCloseAccount,
  approveAccount,
  handleChangeName,
  submitName,
  showTransferModal,
}) => {
  const [selectedAccount, setSelectedAccount] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const confirmDeleteModal = async () => {
    try {
      await deleteAccount(selectedAccount);
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="grid grid-flow-cols gap-4">
      <Card className="overflow-x-auto">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
            All Accounts
          </h1>
          {role ? (
            <div className="flex gap-2">
              <Link to={`/admin/account/${userId}/new`}>
                <Button color="blue">Add Account</Button>
              </Link>
              <Button color="blue" onClick={showTransferModal}>
                Transfer
              </Button>
            </div>
          ) : null}
        </div>
        <div className="px-0 break-all xl:break-normal grid grid-flow-cols gap-4">
          <Table>
            <Table.Head>
              <Table.HeadCell>No</Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Type</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              {role.role ? <Table.HeadCell>Detail</Table.HeadCell> : null}
              <Table.HeadCell>Amount</Table.HeadCell>
              <Table.HeadCell>Created DateTime</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {accountTableData.length === 0
                ? null
                : accountTableData.map((row, index) => (
                    <Table.Row
                      key={index}
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {index + 1}
                      </Table.Cell>
                      {role ? (
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {
                            <TextInput
                              value={row.name}
                              onChange={(e) => handleChangeName(e, row.id)}
                              onKeyDown={(e) => submitName(e, row.id)}
                            />
                          }
                        </Table.Cell>
                      ) : (
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.name}
                        </Table.Cell>
                      )}
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {convertType(row.type)}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <StatusComponent status={row.status} />
                      </Table.Cell>
                      {row.role ? (
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.detail}
                        </Table.Cell>
                      ) : null}
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {row.balance.toFixed(2)}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {row.createDateTime}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {role ? (
                          <div className=" flex space-x-4">
                            {row.status ? (
                              <div className="h-5 w-5"></div>
                            ) : (
                              <button
                                onClick={() => {
                                  if (row.detail === "Open") {
                                    approveAccount(row.id);
                                  } else {
                                    setShowDeleteModal(true);
                                    setSelectedAccount(row.id);
                                  }
                                }}
                              >
                                <FaCheck className="h-5 w-5 text-green-500 cursor-pointer" />
                              </button>
                            )}
                            <button
                              onClick={() => {
                                setShowDeleteModal(true);
                                setSelectedAccount(row.id);
                              }}
                            >
                              <HiTrash className="h-5 w-5 text-red-500 cursor-pointer" />
                            </button>
                          </div>
                        ) : row.status ? (
                          <button onClick={() => deleteAccount(row.id)}>
                            <HiTrash className="h-5 w-5 text-red-500 cursor-pointer" />
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              row.detail === "Open"
                                ? cancelPendingOpenAccount(row.id)
                                : cancelPendingCloseAccount(row.id)
                            }
                          >
                            <MdOutlineCancel className="h-5 w-5 text-red-500 cursor-pointer" />
                          </button>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
            </Table.Body>
          </Table>
          {accountTableData.length === 0 ? (
            <div className="text-lg p-4 text-center">There is no account.</div>
          ) : null}
        </div>
      </Card>
      <ConfirmModal
        showDeleteModal={showDeleteModal}
        cancelDeleteModal={cancelDeleteModal}
        confirmDelete={confirmDeleteModal}
      />
    </div>
  );
};

export default AccountCard;
