const getStatusDetails = (status) => {
  switch (status) {
    case 0:
      return { color: "bg-yellow-300", name: "Pending" };
    case 1:
      return { color: "bg-green-500", name: "Approved" };
    case 2:
      return { color: "bg-red-500", name: "Rejected" };
    case -1:
      return { color: "bg-red-500", name: "Rejected" };
    default:
      return { color: "bg-gray-400", name: "Unknown" };
  }
};

const StatusComponent = (props) => {
  const { color, name } = getStatusDetails(props.status);

  return (
    <div className="flex items-center">
      <span className={`w-3 h-3 me-3 rounded-full ${color}`}></span>
      <span>{name}</span>
    </div>
  );
};

export default StatusComponent;
