import { BiEnvelope, BiPhone, BiGlobeAlt, BiLogoInstagram } from 'react-icons/bi'

const ContactUs = () => {
    return (
        <div className="text-base md:text-lg break-all">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="p-4">
                    <div className="text-2xl md:text-3xl font-bold mt-2">Contact Us For Any Questions</div>
                    <p className="mt-8">
                    WWM VIP is a trading name of West Wealth Management Ltd, which is incorporated in the United Kingdom, and registered by the Financial Services Authority, with an address at Assheton Lane, Twycross, Atherstone, Warwickshire, England, CV9 3QQ. WWM VIP is 100% owned by West Wealth Management Financial Services Ltd, a company incorporated in the UK.
                    </p>
                </div>
            
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 h-full">
                <div className="p-4 flex flex-col h-full">
                    <div className='text-2xl md:text-3xl font-bold'>Contact Info:</div>
                    <div className='flex flex-col mt-8 text-lg md:text-xl flex-grow'>
                        <div className='flex flex-row items-center'><BiEnvelope className='m-3 text-color font-bold' />contact@wwmvip.com</div>
                        <div className='flex flex-row items-center'><BiPhone className='m-3 text-color font-bold' />+44 748 8882881, +1 509 7036738</div>
                        <div className='flex flex-row items-center'><BiGlobeAlt className='m-3 text-color font-bold' />Assheton Lane, Twycross, Warwickshire, CV9 3QQ</div>
                        {/* <div className='flex flex-row items-center'><BiLogoInstagram className='m-3 text-color font-bold' />West Wealth Management Ltd</div> */}
                    </div>
                </div>

                <div className="p-4 flex flex-col h-full">
                    <div className="flex flex-col space-y-4 flex-grow">
                        <div className="flex flex-col md:flex-row space-x-0 space-y-4 md:space-x-4 md:space-y-0">
                            <input type="text" placeholder="Name" className="flex-1 p-4 rounded-md dark:bg-secondary-dark ring-0 outline-none" />
                            <input type="text" placeholder="Subject" className="flex-1 p-4 rounded-md dark:bg-secondary-dark ring-0 outline-none" />
                        </div>
                        <div>
                            <input type="text" placeholder="Email" className="w-full p-4 rounded-md dark:bg-secondary-dark ring-0 outline-none" />
                        </div>
                        <div className="flex-grow">
                            <textarea placeholder="Message" className="w-full p-4 rounded-md h-full dark:bg-secondary-dark ring-0 outline-none"></textarea>
                        </div>
                    </div>
                    <button to='/signup' className="w-1/3 md:w-1/4 bg-primary-button text-white py-2 px-3 md:py-3 md:px-4 rounded-full mt-4">Send Now</button>
                </div>
            </div>
        </div>
    )
}


export default ContactUs;