import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  TextInput,
  Label,
  Select,
  Datepicker,
  Card,
} from "flowbite-react";
import countryList from "react-select-country-list";
import NewsComponent from "../../components/NewsComponent";
import api from "../../utils/api";
import { convertType } from "../../utils/config";

const dateToText = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const AccountVerification = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    gender: 1,
    isVerified: 0,
    birthDate: "",
    phoneNumber: "",
    country: "",
    city: "",
    address: "",
    postalCode: "",
    id: "",
    registrationDate: "",
    lastLoginDate: "",
  });

  const [purchasedQuantity, setPurchasedQuantity] = useState(0);
  const [accountList, setAccountList] = useState([]);
  const countryOptions = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    async function fetchAccountList() {
      try {
        const response = await api.get("/api/v1/account/user");
        setAccountList(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    async function fetchData() {
      try {
        const response = await api.get("/api/v1/profile/user");
        const data = response.data;
        setPurchasedQuantity(data.purchasedQuantity);
        setFormData({
          username: data.username || "",
          email: data.email || "",
          gender: data.gender,
          isVerified: data.isVerified || 0,
          birthDate: data.birthDate || "",
          phoneNumber: data.phoneNumber || "",
          country: data.country || "",
          city: data.city || "",
          address: data.address || "",
          postalCode: data.postalCode || "",
          id: data.id || "",
          registrationDate: data.registrationDate || "",
          lastLoginDate: data.lastLoginDate || "",
        });
      } catch (err) {
        console.log(err);
      }
    }

    fetchAccountList();
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      birthDate: dateToText(date),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.patch("/api/v1/profile/user", { ...formData });
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const totalBalance = accountList
    .filter((account) => account.status === 1)
    .reduce((total, account) => total + account.balance, 0);

  return (
    <div className="gap-4">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Personal Information
        </h1>
      </div>
      <Card>
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold dark:text-white">
            General information
          </h3>
          <div className="flex gap-8 text-xl">
            <span>Total Balance: ${totalBalance}</span>
            <span>Quantity of Contracts Purchased: {purchasedQuantity}</span>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="username" value="Name" />
              </div>
              <TextInput
                placeholder="John Doe"
                id="username"
                defaultValue={formData.username}
                readOnly
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                placeholder="john.doe@test.com"
                id="email"
                defaultValue={formData.email}
                readOnly
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="gender" value="Gender" />
              </div>
              <Select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value={1}>Male</option>
                <option value={0}>Female</option>
              </Select>
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label
                  htmlFor="verification-status"
                  value="Verification Status"
                />
              </div>
              <Select
                id="verification-status"
                value={formData.isVerified}
                disabled
              >
                <option value={0}>Pending</option>
                <option value={1}>Approved</option>
              </Select>
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="birthDate" value="BirthDate" />
              </div>
              <Datepicker
                id="birthDate"
                name="birthDate"
                value={formData.birthDate}
                // selected={new Date(formData.birthDate)}
                onSelectedDateChanged={(date) => handleDateChange(date)}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="phoneNumber" value="Phone Number" />
              </div>
              <TextInput
                placeholder="+447919712920"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="country" value="Country" />
              </div>
              <Select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                {countryOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="city" value="City" />
              </div>
              <TextInput
                placeholder="City"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="address" value="Address" />
              </div>
              <TextInput
                placeholder="Address"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="postalCode" value="Postal Code" />
              </div>
              <TextInput
                placeholder="Postal Code"
                id="postalCode"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="registrationDate" value="Registration Date" />
              </div>
              <TextInput
                placeholder="2000-01-01 08:00"
                id="registrationDate"
                defaultValue={formData.registrationDate}
                readOnly
                disabled
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="lastLoginDate" value="Last Login" />
              </div>
              <TextInput
                placeholder="2000-01-01 08:00"
                id="lastLoginDate"
                defaultValue={formData.lastLoginDate}
                readOnly
                disabled
              />
            </div>

            <div className="col-span-8 sm:col-ful2">
              <Button color="blue" type="submit">
                Save all
              </Button>
            </div>
          </div>
        </form>
      </Card>

      <div className="grid grid-cols-2 mt-4">
        <Card className="col-span-2">
          <h3 className="text-xl font-semibold dark:text-white">News</h3>
          <div className="text-center text-xl">
            <NewsComponent />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AccountVerification;
