import {
  Button,
  TextInput,
  Label,
  Card,
  Textarea,
  Select,
} from "flowbite-react";
import api from "../../utils/api";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaAngleLeft } from "react-icons/fa";
import { convertType } from "../../utils/config";

const TransactionDetail = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const role = useSelector((s) => s.auth.user.role);

  const [formData, setFormData] = useState({
    id: 0,
    transactionNo: "",
    type: 0,
    userName: "",
    useId: 0,
    adminName: "",
    adminId: 0,
    status: 0,
    createDateTime: "",
    purpose: "",
    comment: "",
    amount: 0,
    method: "",
    walletAddress: "",
    network: "",
    accountType: "",
    accountBalance: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/transaction/id/${id}`);
        const data = response.data;

        setFormData({
          id: data.id || 0,
          transactionNo: data.transactionNo || "",
          type: data.type || 0,
          userName: data.userName || "",
          userId: data.userId || 0,
          adminName: data.adminName || "",
          adminId: data.adminId || 0,
          status: data.status || 0,
          createDateTime: data.createDateTime || "",
          purpose: data.purpose || "",
          comment: data.comment || "",
          amount: data.amount || 0,
          method: data.method || "",
          walletAddress: data.walletAddress || "",
          network: data.network || "",
          accountType: convertType(data.accountType) || "",
          accountBalance: data.accountBalance || 0,
        });
      } catch (err) {
        console.log(err);
      }
    }

    if (id !== undefined) fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.patch(`/api/v1/transaction/status-comment/${id}`, {
        ...formData,
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleStatusChange = (e) => {
    setFormData({ ...formData, status: Number(e.target.value) });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          Transaction Detail
        </h1>
      </div>
      <Card>
        <h3 className="text-xl font-semibold dark:text-white">
          Transaction Information
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="transactionNo" value="No" />
              </div>
              <TextInput
                id="transactionNo"
                name="transactionNo"
                value={formData.transactionNo}
                readOnly
              />
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="type" value="Type" />
              </div>
              <TextInput
                id="type"
                name="type"
                value={formData.type ? "Withdraw" : "Deposit"}
                readOnly
              />
            </div>
            {role === 0 ? null : (
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="username" value="User" />
                </div>
                <TextInput
                  id="username"
                  name="userName"
                  value={formData.userName}
                  readOnly
                />
              </div>
            )}
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="account" value="Account" />
              </div>
              <TextInput
                id="account"
                name="accountType"
                value={formData.accountType}
                readOnly
              />
            </div>
            {role === 0 ? null : (
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="adminname" value="Admin" />
                </div>
                <TextInput
                  id="adminname"
                  name="adminName"
                  value={formData.adminName}
                  readOnly
                />
              </div>
            )}

            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="amount" value="Amount" />
              </div>
              <TextInput
                id="amount"
                name="amount"
                value={formData.amount.toFixed(2)}
                readOnly
              />
            </div>
            {formData.type ? (
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="network" value="Network" />
                </div>
                <TextInput
                  id="network"
                  name="network"
                  value={formData.network}
                  readOnly
                />
              </div>
            ) : (
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="method" value="Method" />
                </div>
                <TextInput
                  id="method"
                  name="method"
                  value={formData.method}
                  readOnly
                />
              </div>
            )}
            {formData.type ? (
              <div className="col-span-8 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="wallet-address" value="Wallet Address" />
                </div>
                <TextInput
                  id="wallet-address"
                  name="walletAddress"
                  value={formData.walletAddress}
                  readOnly
                />
              </div>
            ) : null}
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="status" value="Status" />
              </div>
              <Select
                id="status"
                name="status"
                value={formData.status}
                onChange={role === 0 ? null : handleStatusChange}
                readOnly={role === 0}
                className="w-full flex items-center"
              >
                <option value={0} key={0}>
                  Pending
                </option>
                <option value={1} key={1}>
                  Approved
                </option>
                <option value={-1} key={-1}>
                  Rejected
                </option>
              </Select>
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="createDateTime" value="Date" />
              </div>
              <TextInput
                id="createDateTime"
                name="createDateTime"
                value={formData.createDateTime}
                readOnly
              />
            </div>
            <div className="col-span-8">
              <div className="mb-2 block">
                <Label htmlFor="purpose" value="Purpose" />
              </div>
              <Textarea
                id="purpose"
                name="purpose"
                value={formData.purpose}
                onChange={handleChange}
                className="p-2.5"
                rows={4}
                readOnly
              />
            </div>
            <div className="col-span-8">
              <div className="mb-2 block">
                <Label htmlFor="comment" value="Comment" />
              </div>
              <Textarea
                id="comment"
                name="comment"
                value={formData.comment}
                onChange={role === 0 ? null : handleChange}
                readOnly={role === 0}
                className="p-2.5"
                rows={4}
              />
            </div>
            {role === 0 ? null : (
              <div className="col-span-8 sm:col-ful2">
                <Button color="blue" type="submit">
                  Save all
                </Button>
              </div>
            )}
          </div>
        </form>
      </Card>
    </div>
  );
};

export default TransactionDetail;
