import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Sidebar, Button } from "flowbite-react";
import {
  HiUser,
  HiChartPie,
  HiMail,
  HiShoppingCart,
  HiDocumentText,
  HiOutlineDocumentText,
  HiOutlineLockClosed,
} from "react-icons/hi";
import { HiOutlineCpuChip } from "react-icons/hi2";

import { RiContractLine } from "react-icons/ri";

const items = [
  [
    {
      label: "Personal Information",
      key: "general",
      icon: HiUser,
      children: [],
    },
    {
      label: "Account",
      key: "account",
      icon: HiUser,
      children: [],
    },
    {
      label: "Documents",
      key: "documents",
      icon: HiOutlineDocumentText,
      children: [],
    },
    {
      label: "Contracts",
      key: "contracts",
      icon: RiContractLine,
      children: [],
    },
    {
      label: "Transactions",
      key: "transactions",
      icon: HiOutlineCpuChip,
      children: [],
    },
    {
      label: "Notifications",
      key: "notifications",
      icon: HiMail,
      children: [],
    },
    {
      label: "Security",
      key: "security",
      icon: HiOutlineLockClosed,
      children: [],
    },
  ],
  [
    {
      label: "User Management",
      key: "user-management",
      icon: HiChartPie,
      children: [
        { label: "Users", key: "users" },
        { label: "Emplyees", key: "employees" },
      ],
    },
    {
      label: "Transactions",
      key: "transactions",
      icon: HiDocumentText,
      children: [],
    },
    {
      label: "Contracts",
      key: "contracts",
      icon: HiShoppingCart,
      children: [
        { label: "Hot Offers", key: "offers" },
        { label: "Contracts", key: "all" },
      ],
    },
    {
      label: "Notifications",
      key: "notifications",
      icon: HiMail,
      children: [],
    },
    {
      label: "Security",
      key: "security",
      icon: HiOutlineLockClosed,
      children: [],
    },
  ],
  [
    {
      label: "User Management",
      key: "user-management",
      icon: HiChartPie,
      children: [{ label: "Users", key: "users" }],
    },
    {
      label: "Transactions",
      key: "transactions",
      icon: HiDocumentText,
      children: [],
    },
    {
      label: "Contracts",
      key: "contracts",
      icon: HiShoppingCart,
      children: [
        { label: "Hot Offers", key: "offers" },
        { label: "Contracts", key: "all" },
      ],
    },
    {
      label: "Notifications",
      key: "notifications",
      icon: HiMail,
      children: [],
    },
    {
      label: "Security",
      key: "security",
      icon: HiOutlineLockClosed,
      children: [],
    },
  ],
];

const useActiveItem = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const pathname = location.pathname;
    const segments = pathname.split("/");
    const lastSegment = segments.pop() || segments.pop();
    setActiveItem(lastSegment);
  }, [location]);

  return [activeItem, setActiveItem];
};

export function PrivateSidebar({
  toggleSidebar,
  isSidebarVisible,
  showWithDrawModal,
  showDepositModal,
  handleOnClickTalkToAdvisor,
}) {
  const role = useSelector((s) => s.auth.user.role);
  const navItems = role !== undefined ? items[role] : [];
  const [activeItem, setActiveItem] = useActiveItem();

  const handleItemClick = (key) => {
    setActiveItem(key);
  };

  return (
    <Sidebar
      aria-label="Sidebar with multi-level dropdown example"
      // className="sidebar-no-radius border-r border-gray-200 dark:border-gray-700"
    >
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          {navItems.map((item) =>
            item.children.length > 0 ? (
              <Sidebar.Collapse
                key={item.key}
                icon={item.icon}
                label={item.label}
                open={true}
              >
                {item.children.map((child) => (
                  <Sidebar.Item
                    as={Link}
                    key={child.key}
                    to={`${role ? "/admin" : ""}/${item.key}/${child.key}`}
                    className={
                      activeItem === child.key
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                    onClick={() => {
                      if (isSidebarVisible) toggleSidebar();
                      handleItemClick(child.key);
                    }}
                  >
                    {child.label}
                  </Sidebar.Item>
                ))}
              </Sidebar.Collapse>
            ) : (
              <Sidebar.Item
                as={Link}
                key={item.key}
                icon={item.icon}
                to={`${role ? "/admin" : ""}/${item.key}`}
                className={
                  activeItem === item.key ? "bg-gray-100 dark:bg-gray-700" : ""
                }
                onClick={() => {
                  if (isSidebarVisible) toggleSidebar();
                  handleItemClick(item.key);
                }}
              >
                {item.label}
              </Sidebar.Item>
            )
          )}
        </Sidebar.ItemGroup>
        <Button
          color="blue"
          className="w-full mt-4 md:hidden"
          onClick={showWithDrawModal}
        >
          Withdraw
        </Button>
        <Button
          color="blue"
          className="w-full mt-4 md:hidden"
          onClick={showDepositModal}
        >
          Deposit
        </Button>
        <Button
          color="blue"
          className="w-full mt-4 md:hidden"
          onClick={handleOnClickTalkToAdvisor}
        >
          Talk to your advisor
        </Button>
      </Sidebar.Items>
    </Sidebar>
  );
}

export default PrivateSidebar;
