import { Badge } from "flowbite-react";

const StatusBadgeComponent = ({ status }) => {
  if (status === 0)
    return (
      <Badge color="warning" className="ml-2">
        Pending
      </Badge>
    );
  if (status === 1)
    return (
      <Badge color="success" className="ml-2">
        Approved
      </Badge>
    );
  if (status === 2)
    return (
      <Badge color="failure" className="ml-2">
        Rejected
      </Badge>
    );
};

export default StatusBadgeComponent;
