import React from 'react';
import { MarketData } from 'react-ts-tradingview-widgets';

const MarketDataWidget = () => {
  return (
    <div className="market-data-widget-container p-4 md:p-0">
      <MarketData
        colorTheme="dark"
        width="100%"
        height={500}
        isTransparent={false}
        showSymbolLogo={true}
        locale="en"
        displayMode="adaptive"
        symbols={[
          {
            proName: "FOREXCOM:SPXUSD",
            title: "S&P 500",
          },
          {
            proName: "FOREXCOM:NSXUSD",
            title: "US 100",
          },
          {
            proName: "FX_IDC:EURUSD",
            title: "EUR/USD",
          },
          {
            proName: "BITSTAMP:BTCUSD",
            title: "Bitcoin",
          },
          {
            proName: "BITSTAMP:ETHUSD",
            title: "Ethereum",
          },
        ]}
      />
    </div>
  );
};

export default MarketDataWidget;