import { Modal, TextInput, Select, Label, Button } from "flowbite-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { convertType } from "../utils/config";

const TransferModal = ({
  isShowTransfer,
  closeTransferModal,
  accountList,
  onSubmitTransfer,
}) => {
  const [transferData, setTransferData] = useState({
    from: "",
    fromBalance: 0,
    to: "",
    toBalance: 0,
    amount: 0,
  });
  const role = useSelector((s) => s.auth.user.role);

  const handleChangeFrom = (e) => {
    const account = accountList.find(
      (item) => item.id === Number(e.target.value)
    );
    setTransferData({
      ...transferData,
      from: e.target.value,
      fromBalance: account?.balance || 0,
    });
  };

  const handleChangeTo = (e) => {
    const account = accountList.find(
      (item) => item.id === Number(e.target.value)
    );
    setTransferData({
      ...transferData,
      to: e.target.value,
      toBalance: account?.balance || 0,
    });
  };

  const handleChangeAmount = (e) => {
    setTransferData({ ...transferData, amount: e.target.value });
  };

  const closeModal = () => {
    closeTransferModal();
    setTransferData({
      from: "",
      fromBalance: 0,
      to: "",
      toBalance: 0,
      amount: 0,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmitTransfer(transferData);
      setTransferData({
        from: "",
        fromBalance: 0,
        to: "",
        toBalance: 0,
        amount: 0,
      });
    } catch (error) {
      if (
        error.name === "TypeError" &&
        error.message.includes("Failed to fetch")
      ) {
        toast.error("Server is not reachable. Please try again later.");
      } else {
        toast.warn(error.response.data.message);
      }
    }
  };

  return (
    <Modal
      id="transfer-modal"
      show={isShowTransfer}
      size="xl"
      onClose={closeModal}
    >
      <Modal.Header className="p-4">Transfer</Modal.Header>
      <Modal.Body className="px-6 pt-2">
        <form onSubmit={(e) => handleSubmit(e)}>
          {role ? (
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="from-account-name" value="From" />
                </div>
                <Select
                  id="from-account-name"
                  name="from"
                  value={transferData.from}
                  onChange={handleChangeFrom}
                  required
                >
                  <option value="">Select an account</option>
                  {accountList
                    .filter((item) => item.status === 1)
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="from-account" value="Type" />
                </div>
                <Select
                  id="from-account"
                  name="from"
                  value={transferData.from}
                  onChange={handleChangeFrom}
                  required
                  disabled
                >
                  <option value="">Select an account</option>
                  {accountList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {convertType(item.type)}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="from-balance" value="Balance" />
                </div>
                <TextInput
                  id="from-balance"
                  name="fromBalance"
                  value={transferData.fromBalance.toFixed(2)}
                  readOnly
                  disabled
                />
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="to-account-name" value="To" />
                </div>
                <Select
                  id="to-account-name"
                  name="to"
                  value={transferData.to}
                  onChange={handleChangeTo}
                  required
                >
                  <option value="">Select an account</option>
                  {accountList
                    .filter(
                      (item) =>
                        item.id !== Number(transferData.from) &&
                        item.status === 1
                    )
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="to-account" value="Type" />
                </div>
                <Select
                  id="to-account"
                  name="to"
                  value={transferData.to}
                  onChange={handleChangeTo}
                  required
                  disabled
                >
                  <option value="">Select an account</option>
                  {accountList
                    .filter((item) => item.id !== Number(transferData.from))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {convertType(item.type)}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="to-balance" value="Balance" />
                </div>
                <TextInput
                  id="to-balance"
                  name="toBalance"
                  value={transferData.toBalance.toFixed(2)}
                  readOnly
                  disabled
                />
              </div>
              <div className="col-span-12">
                <div className="mb-2 block">
                  <Label htmlFor="amount" value="Amount" />
                </div>
                <TextInput
                  id="amount"
                  name="amount"
                  value={transferData.amount}
                  onChange={handleChangeAmount}
                />
              </div>
              <div className="col-span-12">
                <Button color="blue" type="submit" className="w-full">
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-4 md:col-span-2">
                <div className="mb-2 block">
                  <Label htmlFor="from-account" value="From" />
                </div>
                <Select
                  id="from-account"
                  name="from"
                  value={transferData.from}
                  onChange={handleChangeFrom}
                  required
                >
                  <option value="">Select an account</option>
                  {accountList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {convertType(item.type)}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-span-4 md:col-span-2">
                <div className="mb-2 block">
                  <Label htmlFor="from-balance" value="Balance" />
                </div>
                <TextInput
                  id="from-balance"
                  name="fromBalance"
                  value={transferData.fromBalance.toFixed(2)}
                  readOnly
                  disabled
                />
              </div>
              <div className="col-span-4 md:col-span-2">
                <div className="mb-2 block">
                  <Label htmlFor="to-account" value="To" />
                </div>
                <Select
                  id="to-account"
                  name="to"
                  value={transferData.to}
                  onChange={handleChangeTo}
                  required
                >
                  <option value="">Select an account</option>
                  {accountList
                    .filter((item) => item.id !== Number(transferData.from))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {convertType(item.type)}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="col-span-4 md:col-span-2">
                <div className="mb-2 block">
                  <Label htmlFor="to-balance" value="Balance" />
                </div>
                <TextInput
                  id="to-balance"
                  name="toBalance"
                  value={transferData.toBalance.toFixed(2)}
                  readOnly
                  disabled
                />
              </div>
              <div className="col-span-4">
                <div className="mb-2 block">
                  <Label htmlFor="amount" value="Amount" />
                </div>
                <TextInput
                  id="amount"
                  name="amount"
                  value={transferData.amount}
                  onChange={handleChangeAmount}
                />
              </div>
              <div className="col-span-4">
                <Button color="blue" type="submit" className="w-full">
                  Submit
                </Button>
              </div>
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TransferModal;
