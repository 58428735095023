import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Card, Label, TextInput } from "flowbite-react";
import { HiEye } from "react-icons/hi";
import api from "../../utils/api";

const Contracts = () => {
  const [activeContractTableData, setActiveContractTable] = useState([]);
  const [offerTableData, setOfferTableData] = useState([]);

  const [filterOptions, setFilterOptions] = useState({
    minAmount: "",
    maxAmount: "",
    profit: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const activeContractsData = (await api.get("/api/v1/contracts/active"))
          .data;
        const offersData = (await api.get("/api/v1/offers/all")).data;

        setOfferTableData(offersData);
        setActiveContractTable(activeContractsData);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      try {
        const response = await api.get(
          `/api/v1/offers/all?min_amount=${filterOptions.minAmount}&max_amount=${filterOptions.maxAmount}&profit=${filterOptions.profit}`
        );
        setOfferTableData(response.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="p-0">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          Contracts
        </h1>
      </div>
      <div className="grid grid-flow-cols gap-4">
        <Card className="overflow-x-auto">
          <h3 className="text-xl font-semibold dark:text-white">
            Active Contracts
          </h3>
          <div className="px-0 break-all xl:break-normal grid grid-flow-cols gap-4">
            <Table>
              <Table.Head>
                <Table.HeadCell>No</Table.HeadCell>
                <Table.HeadCell>Title</Table.HeadCell>
                <Table.HeadCell>Price</Table.HeadCell>
                <Table.HeadCell>Profit</Table.HeadCell>
                <Table.HeadCell>Lock Period</Table.HeadCell>
                <Table.HeadCell>Created DateTime</Table.HeadCell>
                <Table.HeadCell>Detail</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {activeContractTableData.length === 0
                  ? null
                  : activeContractTableData.map((row, index) => (
                      <Table.Row
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {index + 1}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.title}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.price.toFixed(2)}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.profitPercentage}%
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.lockPeriod}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.createDateTime}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          <Link
                            to={`/contracts/active/${row.id}`}
                            className="text-blue-600 hover:underline dark:text-blue-500"
                          >
                            <HiEye className="h-5 w-5" />
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
            {activeContractTableData.length === 0 ? (
              <div className="text-lg p-4 text-center">
                There is no active contract.
              </div>
            ) : null}
          </div>
        </Card>
        <Card className="overflow-x-auto">
          <h3 className="text-xl font-semibold dark:text-white">Hot Offers</h3>
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="min_amount" value="Min Amount" />
              </div>
              <TextInput
                id="min_amount"
                type="number"
                name="minAmount"
                value={filterOptions.minAmount}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="max_amount" value="Max Amount" />
              </div>
              <TextInput
                id="max_amount"
                type="number"
                name="maxAmount"
                value={filterOptions.maxAmount}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-span-8 md:col-span-2">
              <div className="mb-2 block">
                <Label htmlFor="profit" value="Profit" />
              </div>
              <TextInput
                id="profit"
                type="number"
                name="profit"
                value={filterOptions.profit}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="px-0 break-all xl:break-normal grid grid-flow-cols gap-4">
            <Table>
              <Table.Head>
                <Table.HeadCell>No</Table.HeadCell>
                <Table.HeadCell>Title</Table.HeadCell>
                <Table.HeadCell>Price</Table.HeadCell>
                <Table.HeadCell>Profit</Table.HeadCell>
                <Table.HeadCell>Lock Period</Table.HeadCell>
                <Table.HeadCell>Created DateTime</Table.HeadCell>
                <Table.HeadCell>Detail</Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y">
                {offerTableData.length === 0
                  ? null
                  : offerTableData.map((row, index) => (
                      <Table.Row
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {index + 1}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.title}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.price.toFixed(2)}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.profitPercentage}%
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.lockPeriod}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {row.createDateTime}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          <Link
                            to={`/offer/${row.id}`}
                            className="text-blue-600 hover:underline dark:text-blue-500"
                          >
                            <HiEye className="h-5 w-5" />
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
            {offerTableData.length === 0 ? (
              <div className="text-lg p-4 text-center">There is no offer.</div>
            ) : null}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Contracts;
