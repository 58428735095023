import axios from "axios";
import { FETCH_NOTIFICATIONS } from "./types";

export const fetchNotifications = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `https://${process.env.REACT_APP_SERVER_DOMAIN_NAME}/api/v1/notifications/unread`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    dispatch({
      type: FETCH_NOTIFICATIONS,
      payload: response.data.count,
    });
  } catch (error) {
    console.log(error);
  }
};
