import { Button, TextInput, Label, Card, Textarea } from "flowbite-react";
import api from "../../utils/api";

import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

const UserDetail = () => {
  const { id } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    lockPeriod: 0,
    price: 0,
    profitPercentage: 0,
    description: "",
    paymentLink: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/api/v1/offers/id/${id}`);
        const data = response.data;

        setFormData({
          title: data.title || "",
          lockPeriod: data.lockPeriod || 0,
          price: data.price || 0,
          profitPercentage: data.profitPercentage || 0,
          description: data.description || "",
          paymentLink: data.paymentLink || "",
        });
      } catch (err) {
        console.log(err);
      }
    }

    if (id !== undefined) fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id !== undefined)
        await api.patch(`/api/v1/offers/${id}`, { ...formData });
      else if (location.pathname === "/admin/contracts/offers/new")
        await api.post(`/api/v1/offers/`, { ...formData });

      navigate("/admin/contracts/offers");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="flex items-center text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white mt-2 mb-6">
          <FaAngleLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          {id !== undefined ? "Offer Detail" : "New Offer"}
        </h1>
      </div>
      <Card>
        <h3 className="text-xl font-semibold dark:text-white">
          Offer Information
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="title" value="Title" />
              </div>
              <TextInput
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="lock-period" value="Lock Period" />
              </div>
              <TextInput
                id="lock-period"
                name="lockPeriod"
                type="number"
                value={formData.lockPeriod}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="price" value="Price" />
              </div>
              <TextInput
                id="price"
                name="price"
                type="number"
                value={formData.price}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-4">
              <div className="mb-2 block">
                <Label htmlFor="profit-percentage" value="Profit Percentage" />
              </div>
              <TextInput
                id="profit-percentage"
                name="profitPercentage"
                type="number"
                value={formData.profitPercentage}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8 md:col-span-8">
              <div className="mb-2 block">
                <Label htmlFor="payment-link" value="Payment Link" />
              </div>
              <TextInput
                id="payment-link"
                name="paymentLink"
                value={formData.paymentLink}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-8">
              <div className="mb-2 block">
                <Label htmlFor="description" value="Description" />
              </div>
              <Textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="p-2.5"
                rows={4}
              />
            </div>
            <div className="col-span-8 sm:col-ful2">
              <Button color="blue" type="submit">
                Save all
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default UserDetail;
